import React from 'react';

const LogoVertical = (props) => (
  <svg
    viewBox="0 0 66 2803"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="VERT-BACKDROP" fill="#0A0B09">
        <g id="Group-2">
          <path
            d="M40.6499165,28.6760572 C37.5305963,27.1163971 33.4581505,27.2030449 26.2750492,27.2030449 L19.8674456,27.2030449 L19.8674456,45.2951021 L32.6826528,45.2951021 C36.4908229,45.2951021 40.8188797,45.5550455 43.6782566,42.1801143 C44.4580866,41.3136365 45.5801754,39.7539764 45.5801754,36.8122841 C45.5801754,35.0793284 45.0602887,30.9245672 40.6499165,28.6760572 Z M41.1654709,6.68051736 C38.2237786,3.04564284 32.8559484,3.39223397 28.7835026,3.39223397 L19.8674456,3.39223397 L19.8674456,18.6292467 L23.8532437,18.6292467 C26.8815837,18.6292467 29.9099238,18.6292467 33.029244,18.4559512 C35.8842884,18.286988 38.6570175,17.9360645 40.7322319,15.6832221 C41.4254142,14.9943722 42.6384832,13.5213599 42.6384832,10.9262588 C42.6384832,10.2330765 42.4651876,8.32682525 41.1654709,6.68051736 Z M49.21505,52.395888 C43.8515521,55.424228 38.3927418,55.1642847 32.5093573,55.1642847 L8.52958314,55.1642847 L8.52958314,-5.95706188 L30.4298105,-5.95706188 C33.4581505,-5.95706188 36.5774707,-5.95706188 39.6058107,-5.69711853 C41.512062,-5.52382296 46.533301,-5.00393626 50.5147667,-0.762527254 C51.8144835,0.619504895 54.4095846,3.91212067 54.4095846,9.02000752 C54.4095846,10.7529632 53.9806781,16.8139757 48.8727912,20.0156113 C47.3997789,20.9687369 46.0134143,21.315328 44.2847911,21.6575868 C45.0602887,21.8308823 46.96654,22.1774735 48.959439,23.3038947 C52.0744268,24.9502025 57.2732938,29.018316 57.2732938,37.8520575 C57.2732938,43.9997178 54.6738603,49.2765678 49.21505,52.395888 Z"
            id="Fill-17"
            transform="translate(32.901438, 24.606402) rotate(-630.000000) translate(-32.901438, -24.606402) "
          ></path>
          <path
            d="M32.9908765,60.2034774 L24.5037261,88.3466775 L41.3913791,88.3466775 L32.9908765,60.2034774 Z M47.9679459,110.333553 L44.4197192,98.3891556 L21.4753861,98.3891556 L17.9271593,110.333553 L5.63183884,110.333553 L26.3233296,49.212206 L39.3074999,49.212206 L60.1766186,110.333553 L47.9679459,110.333553 Z"
            id="Fill-18"
            transform="translate(32.904229, 79.772879) rotate(-630.000000) translate(-32.904229, -79.772879) "
          ></path>
          <path
            d="M55.805238,158.557377 C52.9458611,162.196583 46.2826465,168.946446 33.987326,168.946446 C31.9077792,168.946446 26.9731879,168.77315 21.6920055,166.347012 C14.7645152,163.058729 5.41521938,154.749206 5.41521938,136.652817 C5.41521938,133.364533 5.41521938,124.61744 11.0429929,116.567861 C13.9890176,112.322119 20.9988233,104.705779 34.420565,104.705779 C46.109351,104.705779 52.2570112,110.506848 55.0210755,114.401666 C57.0139745,117.343358 58.2313759,120.376031 59.2668169,123.577666 L47.9289544,126.523691 C47.8379742,125.743861 47.4913831,123.750962 46.3649619,121.675747 C44.0298042,117.343358 39.441804,115.094848 34.5938605,115.181496 C31.1322816,115.181496 28.7061436,116.221269 27.4930746,116.827804 C22.9917223,119.076314 17.7971877,124.964031 17.7971877,136.392874 C17.7971877,138.125829 17.8838354,145.399911 21.4320622,150.850056 C22.6451312,152.66966 24.1181435,154.22932 25.8467668,155.355741 C29.4859737,157.864194 33.1208482,158.124138 34.7671561,158.124138 C39.5241194,158.124138 43.0766785,156.222219 46.0183708,152.583012 C47.2357722,151.11 48.1845654,149.294729 48.4445087,148.86149 C48.7910999,148.08166 49.0510432,147.388477 49.5709299,145.83315 L60.3932381,150.334502 C59.0935213,153.44949 57.8847848,155.871295 55.805238,158.557377"
            id="Fill-19"
            transform="translate(32.904229, 136.826112) rotate(-630.000000) translate(-32.904229, -136.826112) "
          ></path>
          <polyline
            id="Fill-20"
            transform="translate(32.904229, 197.951791) rotate(-630.000000) translate(-32.904229, -197.951791) "
            points="45.151893 228.512465 28.8794392 199.771395 18.9192765 211.200238 18.9192765 228.512465 7.58141397 228.512465 7.58141397 167.391118 18.9192765 167.391118 18.9192765 195.439006 43.4232697 167.391118 56.840679 167.391118 36.8380381 190.417767 58.2270435 228.512465 45.151893 228.512465"
          ></polyline>
          <path
            d="M40.7848447,239.204801 C39.7450713,238.338323 37.6655244,236.782996 34.3729087,235.912185 C31.4312164,235.136688 29.8715563,235.223336 24.5037261,235.223336 L18.447046,235.223336 L18.447046,275.739839 L21.4753861,275.739839 C27.6230463,275.739839 31.2579208,275.653191 34.0263175,275.224285 C35.59031,274.964342 39.9183668,274.266827 43.0333546,270.458657 C46.8415247,265.610713 46.6682292,256.694656 46.6682292,255.914826 C46.6682292,246.821141 43.8998325,241.97753 40.7848447,239.204801 Z M41.4736945,284.400285 C36.1925121,286.042261 31.9511031,286.042261 26.4966251,286.042261 L7.01820338,286.042261 L7.01820338,224.920914 L28.9227631,224.920914 C31.6911598,224.920914 35.0704233,225.09421 37.7521722,225.614096 C40.7848447,226.220631 47.3614114,228.209197 52.4692983,234.790097 C55.1510472,238.338323 58.7902541,245.179166 58.7902541,256.001474 C58.7902541,268.123499 54.1156062,280.327839 41.4736945,284.400285 Z"
            id="Fill-21"
            transform="translate(32.904229, 255.481587) rotate(-630.000000) translate(-32.904229, -255.481587) "
          ></path>
          <path
            d="M39.3118323,295.374227 C37.3189333,294.07451 34.9837755,293.554623 30.1314997,293.554623 L20.8688516,293.554623 L20.8688516,312.599806 L24.936965,312.599806 C31.604512,312.599806 35.7592732,312.599806 38.5320023,311.131126 C40.2606256,310.178001 43.11567,307.929491 43.11567,302.990567 C43.11567,301.435239 42.9423745,297.796032 39.3118323,295.374227 Z M43.2066502,344.806788 L31.1712731,322.122398 L20.8688516,322.122398 L20.8688516,344.806788 L9.52665671,344.806788 L9.52665671,283.685441 L34.5462042,283.685441 C37.3189333,283.685441 45.541808,283.945384 51.0006184,291.132818 C54.5488451,295.803133 54.7221407,301.084316 54.7221407,303.077215 C54.7221407,304.030341 54.6354929,307.058681 53.5913871,309.918057 C52.6425939,312.426511 49.7009016,317.707693 42.6867635,319.873888 L56.2818008,344.806788 L43.2066502,344.806788 Z"
            id="Fill-22"
            transform="translate(32.904229, 314.246114) rotate(-630.000000) translate(-32.904229, -314.246114) "
          ></path>
          <path
            d="M47.0596942,358.768528 C43.8537262,353.573993 39.0057827,350.714616 32.8581225,350.714616 C29.0499524,350.714616 26.2815557,351.927685 25.0728191,352.53422 C20.74043,354.869378 15.3725998,360.583799 15.3725998,372.012642 C15.3725998,374.352132 15.4592475,385.607679 24.0287133,391.23112 C27.754568,393.657258 31.4760903,393.830553 33.0314181,393.830553 C34.2488194,393.830553 37.0172161,393.743906 40.0498885,392.357541 C44.5512409,390.282327 50.4346254,385.087792 50.6989011,373.572302 C50.7812165,368.551063 49.8280909,363.183232 47.0596942,358.768528 Z M53.7272412,395.736805 C52.3408766,397.205485 48.7926499,400.758044 43.3338395,402.74661 C41.4319207,403.439793 37.8836939,404.479566 32.6848269,404.479566 C24.0287133,404.479566 17.4521466,401.620189 11.7333928,395.043622 C6.0233039,388.549371 3.59716596,380.933031 3.59716596,372.44588 C3.59716596,370.457314 3.68381375,365.778334 5.41243703,360.583799 C9.39823508,348.375126 19.8739521,340.238899 32.9491027,340.238899 C44.7245365,340.238899 51.7343422,346.646503 54.593719,349.934786 C57.7953546,353.573993 62.296707,360.670447 62.2100592,372.359233 C62.2100592,376.167403 61.7768203,386.989711 53.7272412,395.736805 Z"
            id="Fill-23"
            transform="translate(32.904229, 372.359233) rotate(-630.000000) translate(-32.904229, -372.359233) "
          ></path>
          <path
            d="M42.4915653,417.439051 C39.8964642,412.937699 34.784245,412.937699 30.3695404,412.937699 L21.626779,412.937699 L21.626779,434.149077 L29.8496537,434.149077 C30.6294837,434.149077 31.4959616,434.062429 32.2757916,434.062429 C38.1591761,433.802485 40.7586096,431.982882 42.231622,429.561076 C42.8381564,428.521303 43.8779298,426.268461 43.8779298,423.153473 C43.8779298,421.251554 43.4446909,419.172007 42.4915653,417.439051 Z M47.5128044,440.036793 C42.231622,443.411725 36.5172006,443.498372 30.5471683,443.498372 L21.626779,443.498372 L21.626779,464.276511 L10.1979363,464.276511 L10.1979363,403.155164 L30.8027793,403.155164 C32.2757916,403.155164 33.7488039,403.068517 35.2218163,403.155164 C37.9035652,403.32846 45.6065531,403.848347 51.0653635,410.25595 C55.4757357,415.359505 55.6490313,421.078258 55.6490313,423.500064 C55.6490313,433.022655 50.7187724,437.957247 47.5128044,440.036793 Z"
            id="Fill-24"
            transform="translate(32.923484, 433.696583) rotate(-630.000000) translate(-32.923484, -433.696583) "
          ></path>
          <g
            id="Group-Copy-5"
            transform="translate(32.799785, 929.613275) rotate(-630.000000) translate(-32.799785, -929.613275) translate(-430.700215, 897.113275)"
          >
            <path
              d="M32.2900207,36.4680022 C29.1707005,34.9083421 25.1025871,34.9949899 17.9194858,34.9949899 L11.5075498,34.9949899 L11.5075498,53.0870471 L24.322757,53.0870471 C28.1352595,53.0870471 32.4633163,53.3469904 35.3183608,49.9720593 C36.0981908,49.1055814 37.224612,47.5459213 37.224612,44.6042291 C37.224612,42.8712734 36.7047253,38.7165122 32.2900207,36.4680022 Z M32.8099074,14.4724623 C29.8638828,10.8375878 24.4960526,11.1841789 20.4279391,11.1841789 L11.5075498,11.1841789 L11.5075498,26.4211917 L15.4933479,26.4211917 C18.5216879,26.4211917 21.5500279,26.4211917 24.6693482,26.2478961 C27.5243926,26.0789329 30.2971217,25.7280094 32.3723361,23.475167 C33.0698508,22.7863171 34.2785874,21.3133048 34.2785874,18.7182037 C34.2785874,18.0250214 34.1052918,16.1187702 32.8099074,14.4724623 Z M40.8638189,60.1878329 C35.4916563,63.216173 30.0371784,62.9562296 24.1494615,62.9562296 L0.169687333,62.9562296 L0.169687333,1.83488306 L22.074247,1.83488306 C25.1025871,1.83488306 28.2219073,1.83488306 31.2502473,2.09482641 C33.1521662,2.26812198 38.1734052,2.78800868 42.1592033,7.02941769 C43.45892,8.41144984 46.0540212,11.7040656 46.0540212,16.8119525 C46.0540212,18.5449081 45.6207822,24.6059206 40.5128954,27.8075562 C39.0442155,28.7606818 37.6578509,29.107273 35.9248953,29.4495317 C36.7047253,29.6228273 38.6109765,29.9694184 40.5995432,31.0958396 C43.7188634,32.7421475 48.913398,36.8102609 48.913398,45.6440025 C48.913398,51.7916627 46.3139645,57.0685127 40.8638189,60.1878329 Z"
              id="Fill-1"
            ></path>
            <path
              d="M79.7990005,12.8261544 L71.3118501,40.9693545 L88.1951707,40.9693545 L79.7990005,12.8261544 Z M94.7760699,62.9562296 L91.2278432,51.0118327 L68.2835101,51.0118327 L64.7309509,62.9562296 L52.4356304,62.9562296 L73.1314535,1.83488306 L86.1199563,1.83488306 L106.98041,62.9562296 L94.7760699,62.9562296 Z"
              id="Fill-2"
            ></path>
            <path
              d="M159.662263,54.1268205 C156.807218,57.7660274 150.139671,64.5158897 137.844351,64.5158897 C135.769136,64.5158897 130.834545,64.3425942 125.54903,61.9164562 C118.625872,58.6281728 109.276576,50.3186504 109.276576,32.2222608 C109.276576,28.9339774 109.276576,20.1868836 114.900018,12.1373045 C117.846042,7.89156313 124.86018,0.275222952 138.27759,0.275222952 C149.966376,0.275222952 156.114036,6.07629207 158.886765,9.97110994 C160.875332,12.9128022 162.088401,15.9454746 163.123842,19.1471102 L151.785979,22.0931349 C151.699331,21.3133048 151.348408,19.3204058 150.226319,17.2451914 C147.891161,12.9128022 143.298829,10.6642922 138.450885,10.75094 C134.989306,10.75094 132.563168,11.7907134 131.350099,12.3972479 C126.848747,14.6457579 121.654212,20.5334748 121.654212,31.9623174 C121.654212,33.6952731 121.74086,40.9693545 125.293419,46.4195001 C126.502156,48.2391036 127.975168,49.7987637 129.708124,50.9251849 C133.342998,53.4336382 136.977873,53.6935816 138.624181,53.6935816 C143.389809,53.6935816 146.938036,51.7916627 149.88406,48.1524558 C151.092797,46.6794435 152.045922,44.8641724 152.301533,44.4309335 C152.648125,43.6511034 152.9124,42.9579212 153.432287,41.4025935 L164.250263,45.9039458 C162.954878,49.0189336 161.741809,51.4407392 159.662263,54.1268205"
              id="Fill-3"
            ></path>
            <polyline
              id="Fill-4"
              points="210.138929 62.9562296 193.862143 34.2151598 183.906313 45.6440025 183.906313 62.9562296 172.564118 62.9562296 172.564118 1.83488306 183.906313 1.83488306 183.906313 29.8827706 208.405973 1.83488306 221.827715 1.83488306 201.829407 24.8615316 223.209747 62.9562296 210.138929 62.9562296"
            ></polyline>
            <path
              d="M263.297344,16.1187702 C262.257571,15.2522924 260.182356,13.6969646 256.889741,12.8261544 C253.948048,12.0506567 252.388388,12.1373045 247.020558,12.1373045 L240.959546,12.1373045 L240.959546,52.6538082 L243.987886,52.6538082 C250.135546,52.6538082 253.774753,52.5671604 256.547482,52.1382538 C258.10281,51.8783105 262.430866,51.1807958 265.550187,47.3726257 C269.358357,42.5246823 269.185061,33.6086253 269.185061,32.8287953 C269.185061,23.7351104 266.412332,18.8914993 263.297344,16.1187702 Z M263.986194,61.3142541 C258.709344,62.9562296 254.463603,62.9562296 249.009125,62.9562296 L229.530703,62.9562296 L229.530703,1.83488306 L251.435263,1.83488306 C254.207992,1.83488306 257.582923,2.00817862 260.269004,2.52806533 C263.297344,3.13459981 269.878243,5.12316645 274.98613,11.7040656 C277.672212,15.2522924 281.307086,22.0931349 281.307086,32.915443 C281.307086,45.037468 276.628106,57.2418083 263.986194,61.3142541 Z"
              id="Fill-5"
            ></path>
            <path
              d="M320.588859,13.5236691 C318.59596,12.2239523 316.260802,11.7040656 311.412859,11.7040656 L302.145878,11.7040656 L302.145878,30.7492485 L306.218324,30.7492485 C312.885871,30.7492485 317.040632,30.7492485 319.809029,29.2805685 C321.541984,28.3274429 324.401361,26.0789329 324.401361,21.1400093 C324.401361,19.5846815 324.228066,15.9454746 320.588859,13.5236691 Z M324.483677,62.9562296 L312.452632,40.2718399 L302.145878,40.2718399 L302.145878,62.9562296 L290.808016,62.9562296 L290.808016,1.83488306 L315.827563,1.83488306 C318.59596,1.83488306 326.823167,2.09482641 332.277645,9.28226006 C335.830204,13.9525756 336.0035,19.233758 336.0035,21.226657 C336.0035,22.1797827 335.912519,25.2081227 334.877078,28.0674996 C333.923953,30.5759529 330.977928,35.8571353 323.96379,38.0233299 L337.558827,62.9562296 L324.483677,62.9562296 Z"
              id="Fill-6"
            ></path>
            <path
              d="M386.454172,18.8048515 C383.248204,13.6103169 378.40026,10.75094 372.256932,10.75094 C368.44443,10.75094 365.671701,11.964009 364.462964,12.5705435 C360.134907,14.9057012 354.767077,20.6201226 354.767077,32.0489652 C354.767077,34.3884554 354.849392,45.6440025 363.423191,51.2674436 C367.144713,53.6935816 370.866235,53.8668771 372.425895,53.8668771 C373.638964,53.8668771 376.411693,53.7802293 379.440033,52.3938648 C383.941386,50.3186504 389.829103,45.1241158 390.089046,33.6086253 C390.175694,28.5873863 389.222568,23.2195561 386.454172,18.8048515 Z M393.117386,55.7731284 C391.735354,57.2418083 388.182795,60.7943674 382.732649,62.7829341 C380.822066,63.4761163 377.273839,64.5158897 372.083637,64.5158897 C363.423191,64.5158897 356.842292,61.6565129 351.12787,55.0799461 C345.413449,48.5856947 342.991643,40.9693545 342.991643,32.4822041 C342.991643,30.4936375 343.073959,25.8146572 344.811247,20.6201226 C348.792712,8.41144984 359.268429,0.275222952 372.339248,0.275222952 C384.114681,0.275222952 391.12882,6.68282655 393.983864,9.97110994 C397.189832,13.6103169 401.691184,20.7067703 401.604537,32.3955563 C401.604537,36.2037264 401.171298,47.0260346 393.117386,55.7731284 Z"
              id="Fill-7"
            ></path>
            <path
              d="M443.204137,16.1187702 C440.604704,11.6174178 435.496817,11.6174178 431.082113,11.6174178 L422.335019,11.6174178 L422.335019,32.8287953 L430.562226,32.8287953 C431.337723,32.8287953 432.208534,32.7421475 432.984031,32.7421475 C438.876081,32.4822041 441.471182,30.6626007 442.944194,28.2407951 C443.550729,27.2010217 444.58617,24.9481793 444.58617,21.8331915 C444.58617,19.9312727 444.152931,17.8517259 443.204137,16.1187702 Z M448.225377,38.7165122 C442.944194,42.0914433 437.229773,42.1780911 431.255408,42.1780911 L422.335019,42.1780911 L422.335019,62.9562296 L410.910509,62.9562296 L410.910509,1.83488306 L431.511019,1.83488306 C432.984031,1.83488306 434.457044,1.74823527 435.930056,1.83488306 C438.611805,2.00817862 446.319125,2.52806533 451.773603,8.93566893 C456.188308,14.0392234 456.361603,19.7579771 456.361603,22.1797827 C456.361603,31.7023741 451.427012,36.6369654 448.225377,38.7165122 Z"
              id="Fill-8"
            ></path>
            <path
              d="M502.241605,36.4680022 C499.122285,34.9083421 495.049839,34.9949899 487.866738,34.9949899 L481.459134,34.9949899 L481.459134,53.0870471 L494.274341,53.0870471 C498.082511,53.0870471 502.410568,53.3469904 505.269945,49.9720593 C506.049775,49.1055814 507.171864,47.5459213 507.171864,44.6042291 C507.171864,42.8712734 506.651977,38.7165122 502.241605,36.4680022 Z M502.757159,14.4724623 C499.815467,10.8375878 494.447637,11.1841789 490.375191,11.1841789 L481.459134,11.1841789 L481.459134,26.4211917 L485.444932,26.4211917 C488.473272,26.4211917 491.501612,26.4211917 494.620932,26.2478961 C497.475977,26.0789329 500.248706,25.7280094 502.32392,23.475167 C503.017103,22.7863171 504.230171,21.3133048 504.230171,18.7182037 C504.230171,18.0250214 504.056876,16.1187702 502.757159,14.4724623 Z M510.806738,60.1878329 C505.44324,63.216173 499.98443,62.9562296 494.101046,62.9562296 L470.121271,62.9562296 L470.121271,1.83488306 L492.021499,1.83488306 C495.049839,1.83488306 498.169159,1.83488306 501.197499,2.09482641 C503.10375,2.26812198 508.124989,2.78800868 512.106455,7.02941769 C513.406172,8.41144984 516.001273,11.7040656 516.001273,16.8119525 C516.001273,18.5449081 515.572366,24.6059206 510.46448,27.8075562 C508.991467,28.7606818 507.605103,29.107273 505.876479,29.4495317 C506.651977,29.6228273 508.558228,29.9694184 510.551127,31.0958396 C513.666115,32.7421475 518.864982,36.8102609 518.864982,45.6440025 C518.864982,51.7916627 516.265549,57.0685127 510.806738,60.1878329 Z"
              id="Fill-17"
            ></path>
            <path
              d="M549.746252,12.8261544 L541.259102,40.9693545 L558.146755,40.9693545 L549.746252,12.8261544 Z M564.723322,62.9562296 L561.175095,51.0118327 L538.230762,51.0118327 L534.682535,62.9562296 L522.387215,62.9562296 L543.078705,1.83488306 L556.062876,1.83488306 L576.931994,62.9562296 L564.723322,62.9562296 Z"
              id="Fill-18"
            ></path>
            <path
              d="M629.613847,54.1268205 C626.75447,57.7660274 620.091255,64.5158897 607.795935,64.5158897 C605.716388,64.5158897 600.781797,64.3425942 595.500614,61.9164562 C588.573124,58.6281728 579.223828,50.3186504 579.223828,32.2222608 C579.223828,28.9339774 579.223828,20.1868836 584.851602,12.1373045 C587.797626,7.89156313 594.807432,0.275222952 608.229174,0.275222952 C619.91796,0.275222952 626.06562,6.07629207 628.829684,9.97110994 C630.822583,12.9128022 632.039985,15.9454746 633.075426,19.1471102 L621.737563,22.0931349 C621.646583,21.3133048 621.299992,19.3204058 620.173571,17.2451914 C617.838413,12.9128022 613.250413,10.6642922 608.402469,10.75094 C604.94089,10.75094 602.514752,11.7907134 601.301683,12.3972479 C596.800331,14.6457579 591.605797,20.5334748 591.605797,31.9623174 C591.605797,33.6952731 591.692444,40.9693545 595.240671,46.4195001 C596.45374,48.2391036 597.926752,49.7987637 599.655376,50.9251849 C603.294583,53.4336382 606.929457,53.6935816 608.575765,53.6935816 C613.332728,53.6935816 616.885287,51.7916627 619.82698,48.1524558 C621.044381,46.6794435 621.993174,44.8641724 622.253118,44.4309335 C622.599709,43.6511034 622.859652,42.9579212 623.379539,41.4025935 L634.201847,45.9039458 C632.90213,49.0189336 631.693394,51.4407392 629.613847,54.1268205"
              id="Fill-19"
            ></path>
            <polyline
              id="Fill-20"
              points="680.086181 62.9562296 663.813727 34.2151598 653.853564 45.6440025 653.853564 62.9562296 642.515702 62.9562296 642.515702 1.83488306 653.853564 1.83488306 653.853564 29.8827706 678.357557 1.83488306 691.774967 1.83488306 671.772326 24.8615316 693.161331 62.9562296 680.086181 62.9562296"
            ></polyline>
            <path
              d="M733.248928,16.1187702 C732.209155,15.2522924 730.129608,13.6969646 726.836992,12.8261544 C723.8953,12.0506567 722.33564,12.1373045 716.96781,12.1373045 L710.91113,12.1373045 L710.91113,52.6538082 L713.93947,52.6538082 C720.08713,52.6538082 723.722005,52.5671604 726.490401,52.1382538 C728.054394,51.8783105 732.382451,51.1807958 735.497438,47.3726257 C739.305608,42.5246823 739.132313,33.6086253 739.132313,32.8287953 C739.132313,23.7351104 736.363916,18.8914993 733.248928,16.1187702 Z M733.937778,61.3142541 C728.656596,62.9562296 724.415187,62.9562296 718.960709,62.9562296 L699.482287,62.9562296 L699.482287,1.83488306 L721.386847,1.83488306 C724.155244,1.83488306 727.534507,2.00817862 730.216256,2.52806533 C733.248928,3.13459981 739.825495,5.12316645 744.933382,11.7040656 C747.615131,15.2522924 751.254338,22.0931349 751.254338,32.915443 C751.254338,45.037468 746.57969,57.2418083 733.937778,61.3142541 Z"
              id="Fill-21"
            ></path>
            <path
              d="M790.540443,13.5236691 C788.547544,12.2239523 786.212386,11.7040656 781.36011,11.7040656 L772.097462,11.7040656 L772.097462,30.7492485 L776.165576,30.7492485 C782.833123,30.7492485 786.987884,30.7492485 789.760613,29.2805685 C791.489236,28.3274429 794.344281,26.0789329 794.344281,21.1400093 C794.344281,19.5846815 794.170985,15.9454746 790.540443,13.5236691 Z M794.435261,62.9562296 L782.399884,40.2718399 L772.097462,40.2718399 L772.097462,62.9562296 L760.755267,62.9562296 L760.755267,1.83488306 L785.774815,1.83488306 C788.547544,1.83488306 796.770419,2.09482641 802.229229,9.28226006 C805.777456,13.9525756 805.950751,19.233758 805.950751,21.226657 C805.950751,22.1797827 805.864103,25.2081227 804.819998,28.0674996 C803.871204,30.5759529 800.929512,35.8571353 793.915374,38.0233299 L807.510411,62.9562296 L794.435261,62.9562296 Z"
              id="Fill-22"
            ></path>
            <path
              d="M856.401423,18.8048515 C853.195455,13.6103169 848.347512,10.75094 842.199852,10.75094 C838.391681,10.75094 835.623285,11.964009 834.414548,12.5705435 C830.082159,14.9057012 824.714329,20.6201226 824.714329,32.0489652 C824.714329,34.3884554 824.800977,45.6440025 833.370442,51.2674436 C837.096297,53.6935816 840.817819,53.8668771 842.373147,53.8668771 C843.590548,53.8668771 846.358945,53.7802293 849.391618,52.3938648 C853.89297,50.3186504 859.776354,45.1241158 860.04063,33.6086253 C860.122946,28.5873863 859.16982,23.2195561 856.401423,18.8048515 Z M863.06897,55.7731284 C861.682606,57.2418083 858.134379,60.7943674 852.675569,62.7829341 C850.77365,63.4761163 847.225423,64.5158897 842.026556,64.5158897 C833.370442,64.5158897 826.793876,61.6565129 821.075122,55.0799461 C815.365033,48.5856947 812.938895,40.9693545 812.938895,32.4822041 C812.938895,30.4936375 813.025543,25.8146572 814.754166,20.6201226 C818.739964,8.41144984 829.215681,0.275222952 842.290832,0.275222952 C854.066266,0.275222952 861.076071,6.68282655 863.935448,9.97110994 C867.137084,13.6103169 871.638436,20.7067703 871.551788,32.3955563 C871.551788,36.2037264 871.118549,47.0260346 863.06897,55.7731284 Z"
              id="Fill-23"
            ></path>
            <path
              d="M913.151389,16.1187702 C910.556288,11.6174178 905.444069,11.6174178 901.029364,11.6174178 L892.286603,11.6174178 L892.286603,32.8287953 L900.509478,32.8287953 C901.289308,32.8287953 902.155785,32.7421475 902.935616,32.7421475 C908.819,32.4822041 911.418434,30.6626007 912.891446,28.2407951 C913.49798,27.2010217 914.537754,24.9481793 914.537754,21.8331915 C914.537754,19.9312727 914.104515,17.8517259 913.151389,16.1187702 Z M918.172628,38.7165122 C912.891446,42.0914433 907.177025,42.1780911 901.206992,42.1780911 L892.286603,42.1780911 L892.286603,62.9562296 L880.85776,62.9562296 L880.85776,1.83488306 L901.462603,1.83488306 C902.935616,1.83488306 904.408628,1.74823527 905.88164,1.83488306 C908.563389,2.00817862 916.266377,2.52806533 921.725187,8.93566893 C926.13556,14.0392234 926.308855,19.7579771 926.308855,22.1797827 C926.308855,31.7023741 921.378596,36.6369654 918.172628,38.7165122 Z"
              id="Fill-24"
            ></path>
          </g>
        </g>
        <g id="Group-2" transform="translate(0.000000, 1410.000000)">
          <path
            d="M40.6499165,28.6760572 C37.5305963,27.1163971 33.4581505,27.2030449 26.2750492,27.2030449 L19.8674456,27.2030449 L19.8674456,45.2951021 L32.6826528,45.2951021 C36.4908229,45.2951021 40.8188797,45.5550455 43.6782566,42.1801143 C44.4580866,41.3136365 45.5801754,39.7539764 45.5801754,36.8122841 C45.5801754,35.0793284 45.0602887,30.9245672 40.6499165,28.6760572 Z M41.1654709,6.68051736 C38.2237786,3.04564284 32.8559484,3.39223397 28.7835026,3.39223397 L19.8674456,3.39223397 L19.8674456,18.6292467 L23.8532437,18.6292467 C26.8815837,18.6292467 29.9099238,18.6292467 33.029244,18.4559512 C35.8842884,18.286988 38.6570175,17.9360645 40.7322319,15.6832221 C41.4254142,14.9943722 42.6384832,13.5213599 42.6384832,10.9262588 C42.6384832,10.2330765 42.4651876,8.32682525 41.1654709,6.68051736 Z M49.21505,52.395888 C43.8515521,55.424228 38.3927418,55.1642847 32.5093573,55.1642847 L8.52958314,55.1642847 L8.52958314,-5.95706188 L30.4298105,-5.95706188 C33.4581505,-5.95706188 36.5774707,-5.95706188 39.6058107,-5.69711853 C41.512062,-5.52382296 46.533301,-5.00393626 50.5147667,-0.762527254 C51.8144835,0.619504895 54.4095846,3.91212067 54.4095846,9.02000752 C54.4095846,10.7529632 53.9806781,16.8139757 48.8727912,20.0156113 C47.3997789,20.9687369 46.0134143,21.315328 44.2847911,21.6575868 C45.0602887,21.8308823 46.96654,22.1774735 48.959439,23.3038947 C52.0744268,24.9502025 57.2732938,29.018316 57.2732938,37.8520575 C57.2732938,43.9997178 54.6738603,49.2765678 49.21505,52.395888 Z"
            id="Fill-17"
            transform="translate(32.901438, 24.606402) rotate(-630.000000) translate(-32.901438, -24.606402) "
          ></path>
          <path
            d="M32.9908765,60.2034774 L24.5037261,88.3466775 L41.3913791,88.3466775 L32.9908765,60.2034774 Z M47.9679459,110.333553 L44.4197192,98.3891556 L21.4753861,98.3891556 L17.9271593,110.333553 L5.63183884,110.333553 L26.3233296,49.212206 L39.3074999,49.212206 L60.1766186,110.333553 L47.9679459,110.333553 Z"
            id="Fill-18"
            transform="translate(32.904229, 79.772879) rotate(-630.000000) translate(-32.904229, -79.772879) "
          ></path>
          <path
            d="M55.805238,158.557377 C52.9458611,162.196583 46.2826465,168.946446 33.987326,168.946446 C31.9077792,168.946446 26.9731879,168.77315 21.6920055,166.347012 C14.7645152,163.058729 5.41521938,154.749206 5.41521938,136.652817 C5.41521938,133.364533 5.41521938,124.61744 11.0429929,116.567861 C13.9890176,112.322119 20.9988233,104.705779 34.420565,104.705779 C46.109351,104.705779 52.2570112,110.506848 55.0210755,114.401666 C57.0139745,117.343358 58.2313759,120.376031 59.2668169,123.577666 L47.9289544,126.523691 C47.8379742,125.743861 47.4913831,123.750962 46.3649619,121.675747 C44.0298042,117.343358 39.441804,115.094848 34.5938605,115.181496 C31.1322816,115.181496 28.7061436,116.221269 27.4930746,116.827804 C22.9917223,119.076314 17.7971877,124.964031 17.7971877,136.392874 C17.7971877,138.125829 17.8838354,145.399911 21.4320622,150.850056 C22.6451312,152.66966 24.1181435,154.22932 25.8467668,155.355741 C29.4859737,157.864194 33.1208482,158.124138 34.7671561,158.124138 C39.5241194,158.124138 43.0766785,156.222219 46.0183708,152.583012 C47.2357722,151.11 48.1845654,149.294729 48.4445087,148.86149 C48.7910999,148.08166 49.0510432,147.388477 49.5709299,145.83315 L60.3932381,150.334502 C59.0935213,153.44949 57.8847848,155.871295 55.805238,158.557377"
            id="Fill-19"
            transform="translate(32.904229, 136.826112) rotate(-630.000000) translate(-32.904229, -136.826112) "
          ></path>
          <polyline
            id="Fill-20"
            transform="translate(32.904229, 197.951791) rotate(-630.000000) translate(-32.904229, -197.951791) "
            points="45.151893 228.512465 28.8794392 199.771395 18.9192765 211.200238 18.9192765 228.512465 7.58141397 228.512465 7.58141397 167.391118 18.9192765 167.391118 18.9192765 195.439006 43.4232697 167.391118 56.840679 167.391118 36.8380381 190.417767 58.2270435 228.512465 45.151893 228.512465"
          ></polyline>
          <path
            d="M40.7848447,239.204801 C39.7450713,238.338323 37.6655244,236.782996 34.3729087,235.912185 C31.4312164,235.136688 29.8715563,235.223336 24.5037261,235.223336 L18.447046,235.223336 L18.447046,275.739839 L21.4753861,275.739839 C27.6230463,275.739839 31.2579208,275.653191 34.0263175,275.224285 C35.59031,274.964342 39.9183668,274.266827 43.0333546,270.458657 C46.8415247,265.610713 46.6682292,256.694656 46.6682292,255.914826 C46.6682292,246.821141 43.8998325,241.97753 40.7848447,239.204801 Z M41.4736945,284.400285 C36.1925121,286.042261 31.9511031,286.042261 26.4966251,286.042261 L7.01820338,286.042261 L7.01820338,224.920914 L28.9227631,224.920914 C31.6911598,224.920914 35.0704233,225.09421 37.7521722,225.614096 C40.7848447,226.220631 47.3614114,228.209197 52.4692983,234.790097 C55.1510472,238.338323 58.7902541,245.179166 58.7902541,256.001474 C58.7902541,268.123499 54.1156062,280.327839 41.4736945,284.400285 Z"
            id="Fill-21"
            transform="translate(32.904229, 255.481587) rotate(-630.000000) translate(-32.904229, -255.481587) "
          ></path>
          <path
            d="M39.3118323,295.374227 C37.3189333,294.07451 34.9837755,293.554623 30.1314997,293.554623 L20.8688516,293.554623 L20.8688516,312.599806 L24.936965,312.599806 C31.604512,312.599806 35.7592732,312.599806 38.5320023,311.131126 C40.2606256,310.178001 43.11567,307.929491 43.11567,302.990567 C43.11567,301.435239 42.9423745,297.796032 39.3118323,295.374227 Z M43.2066502,344.806788 L31.1712731,322.122398 L20.8688516,322.122398 L20.8688516,344.806788 L9.52665671,344.806788 L9.52665671,283.685441 L34.5462042,283.685441 C37.3189333,283.685441 45.541808,283.945384 51.0006184,291.132818 C54.5488451,295.803133 54.7221407,301.084316 54.7221407,303.077215 C54.7221407,304.030341 54.6354929,307.058681 53.5913871,309.918057 C52.6425939,312.426511 49.7009016,317.707693 42.6867635,319.873888 L56.2818008,344.806788 L43.2066502,344.806788 Z"
            id="Fill-22"
            transform="translate(32.904229, 314.246114) rotate(-630.000000) translate(-32.904229, -314.246114) "
          ></path>
          <path
            d="M47.0596942,358.768528 C43.8537262,353.573993 39.0057827,350.714616 32.8581225,350.714616 C29.0499524,350.714616 26.2815557,351.927685 25.0728191,352.53422 C20.74043,354.869378 15.3725998,360.583799 15.3725998,372.012642 C15.3725998,374.352132 15.4592475,385.607679 24.0287133,391.23112 C27.754568,393.657258 31.4760903,393.830553 33.0314181,393.830553 C34.2488194,393.830553 37.0172161,393.743906 40.0498885,392.357541 C44.5512409,390.282327 50.4346254,385.087792 50.6989011,373.572302 C50.7812165,368.551063 49.8280909,363.183232 47.0596942,358.768528 Z M53.7272412,395.736805 C52.3408766,397.205485 48.7926499,400.758044 43.3338395,402.74661 C41.4319207,403.439793 37.8836939,404.479566 32.6848269,404.479566 C24.0287133,404.479566 17.4521466,401.620189 11.7333928,395.043622 C6.0233039,388.549371 3.59716596,380.933031 3.59716596,372.44588 C3.59716596,370.457314 3.68381375,365.778334 5.41243703,360.583799 C9.39823508,348.375126 19.8739521,340.238899 32.9491027,340.238899 C44.7245365,340.238899 51.7343422,346.646503 54.593719,349.934786 C57.7953546,353.573993 62.296707,360.670447 62.2100592,372.359233 C62.2100592,376.167403 61.7768203,386.989711 53.7272412,395.736805 Z"
            id="Fill-23"
            transform="translate(32.904229, 372.359233) rotate(-630.000000) translate(-32.904229, -372.359233) "
          ></path>
          <path
            d="M42.4915653,417.439051 C39.8964642,412.937699 34.784245,412.937699 30.3695404,412.937699 L21.626779,412.937699 L21.626779,434.149077 L29.8496537,434.149077 C30.6294837,434.149077 31.4959616,434.062429 32.2757916,434.062429 C38.1591761,433.802485 40.7586096,431.982882 42.231622,429.561076 C42.8381564,428.521303 43.8779298,426.268461 43.8779298,423.153473 C43.8779298,421.251554 43.4446909,419.172007 42.4915653,417.439051 Z M47.5128044,440.036793 C42.231622,443.411725 36.5172006,443.498372 30.5471683,443.498372 L21.626779,443.498372 L21.626779,464.276511 L10.1979363,464.276511 L10.1979363,403.155164 L30.8027793,403.155164 C32.2757916,403.155164 33.7488039,403.068517 35.2218163,403.155164 C37.9035652,403.32846 45.6065531,403.848347 51.0653635,410.25595 C55.4757357,415.359505 55.6490313,421.078258 55.6490313,423.500064 C55.6490313,433.022655 50.7187724,437.957247 47.5128044,440.036793 Z"
            id="Fill-24"
            transform="translate(32.923484, 433.696583) rotate(-630.000000) translate(-32.923484, -433.696583) "
          ></path>
          <g
            id="Group-Copy-5"
            transform="translate(32.799785, 929.613275) rotate(-630.000000) translate(-32.799785, -929.613275) translate(-430.700215, 897.113275)"
          >
            <path
              d="M32.2900207,36.4680022 C29.1707005,34.9083421 25.1025871,34.9949899 17.9194858,34.9949899 L11.5075498,34.9949899 L11.5075498,53.0870471 L24.322757,53.0870471 C28.1352595,53.0870471 32.4633163,53.3469904 35.3183608,49.9720593 C36.0981908,49.1055814 37.224612,47.5459213 37.224612,44.6042291 C37.224612,42.8712734 36.7047253,38.7165122 32.2900207,36.4680022 Z M32.8099074,14.4724623 C29.8638828,10.8375878 24.4960526,11.1841789 20.4279391,11.1841789 L11.5075498,11.1841789 L11.5075498,26.4211917 L15.4933479,26.4211917 C18.5216879,26.4211917 21.5500279,26.4211917 24.6693482,26.2478961 C27.5243926,26.0789329 30.2971217,25.7280094 32.3723361,23.475167 C33.0698508,22.7863171 34.2785874,21.3133048 34.2785874,18.7182037 C34.2785874,18.0250214 34.1052918,16.1187702 32.8099074,14.4724623 Z M40.8638189,60.1878329 C35.4916563,63.216173 30.0371784,62.9562296 24.1494615,62.9562296 L0.169687333,62.9562296 L0.169687333,1.83488306 L22.074247,1.83488306 C25.1025871,1.83488306 28.2219073,1.83488306 31.2502473,2.09482641 C33.1521662,2.26812198 38.1734052,2.78800868 42.1592033,7.02941769 C43.45892,8.41144984 46.0540212,11.7040656 46.0540212,16.8119525 C46.0540212,18.5449081 45.6207822,24.6059206 40.5128954,27.8075562 C39.0442155,28.7606818 37.6578509,29.107273 35.9248953,29.4495317 C36.7047253,29.6228273 38.6109765,29.9694184 40.5995432,31.0958396 C43.7188634,32.7421475 48.913398,36.8102609 48.913398,45.6440025 C48.913398,51.7916627 46.3139645,57.0685127 40.8638189,60.1878329 Z"
              id="Fill-1"
            ></path>
            <path
              d="M79.7990005,12.8261544 L71.3118501,40.9693545 L88.1951707,40.9693545 L79.7990005,12.8261544 Z M94.7760699,62.9562296 L91.2278432,51.0118327 L68.2835101,51.0118327 L64.7309509,62.9562296 L52.4356304,62.9562296 L73.1314535,1.83488306 L86.1199563,1.83488306 L106.98041,62.9562296 L94.7760699,62.9562296 Z"
              id="Fill-2"
            ></path>
            <path
              d="M159.662263,54.1268205 C156.807218,57.7660274 150.139671,64.5158897 137.844351,64.5158897 C135.769136,64.5158897 130.834545,64.3425942 125.54903,61.9164562 C118.625872,58.6281728 109.276576,50.3186504 109.276576,32.2222608 C109.276576,28.9339774 109.276576,20.1868836 114.900018,12.1373045 C117.846042,7.89156313 124.86018,0.275222952 138.27759,0.275222952 C149.966376,0.275222952 156.114036,6.07629207 158.886765,9.97110994 C160.875332,12.9128022 162.088401,15.9454746 163.123842,19.1471102 L151.785979,22.0931349 C151.699331,21.3133048 151.348408,19.3204058 150.226319,17.2451914 C147.891161,12.9128022 143.298829,10.6642922 138.450885,10.75094 C134.989306,10.75094 132.563168,11.7907134 131.350099,12.3972479 C126.848747,14.6457579 121.654212,20.5334748 121.654212,31.9623174 C121.654212,33.6952731 121.74086,40.9693545 125.293419,46.4195001 C126.502156,48.2391036 127.975168,49.7987637 129.708124,50.9251849 C133.342998,53.4336382 136.977873,53.6935816 138.624181,53.6935816 C143.389809,53.6935816 146.938036,51.7916627 149.88406,48.1524558 C151.092797,46.6794435 152.045922,44.8641724 152.301533,44.4309335 C152.648125,43.6511034 152.9124,42.9579212 153.432287,41.4025935 L164.250263,45.9039458 C162.954878,49.0189336 161.741809,51.4407392 159.662263,54.1268205"
              id="Fill-3"
            ></path>
            <polyline
              id="Fill-4"
              points="210.138929 62.9562296 193.862143 34.2151598 183.906313 45.6440025 183.906313 62.9562296 172.564118 62.9562296 172.564118 1.83488306 183.906313 1.83488306 183.906313 29.8827706 208.405973 1.83488306 221.827715 1.83488306 201.829407 24.8615316 223.209747 62.9562296 210.138929 62.9562296"
            ></polyline>
            <path
              d="M263.297344,16.1187702 C262.257571,15.2522924 260.182356,13.6969646 256.889741,12.8261544 C253.948048,12.0506567 252.388388,12.1373045 247.020558,12.1373045 L240.959546,12.1373045 L240.959546,52.6538082 L243.987886,52.6538082 C250.135546,52.6538082 253.774753,52.5671604 256.547482,52.1382538 C258.10281,51.8783105 262.430866,51.1807958 265.550187,47.3726257 C269.358357,42.5246823 269.185061,33.6086253 269.185061,32.8287953 C269.185061,23.7351104 266.412332,18.8914993 263.297344,16.1187702 Z M263.986194,61.3142541 C258.709344,62.9562296 254.463603,62.9562296 249.009125,62.9562296 L229.530703,62.9562296 L229.530703,1.83488306 L251.435263,1.83488306 C254.207992,1.83488306 257.582923,2.00817862 260.269004,2.52806533 C263.297344,3.13459981 269.878243,5.12316645 274.98613,11.7040656 C277.672212,15.2522924 281.307086,22.0931349 281.307086,32.915443 C281.307086,45.037468 276.628106,57.2418083 263.986194,61.3142541 Z"
              id="Fill-5"
            ></path>
            <path
              d="M320.588859,13.5236691 C318.59596,12.2239523 316.260802,11.7040656 311.412859,11.7040656 L302.145878,11.7040656 L302.145878,30.7492485 L306.218324,30.7492485 C312.885871,30.7492485 317.040632,30.7492485 319.809029,29.2805685 C321.541984,28.3274429 324.401361,26.0789329 324.401361,21.1400093 C324.401361,19.5846815 324.228066,15.9454746 320.588859,13.5236691 Z M324.483677,62.9562296 L312.452632,40.2718399 L302.145878,40.2718399 L302.145878,62.9562296 L290.808016,62.9562296 L290.808016,1.83488306 L315.827563,1.83488306 C318.59596,1.83488306 326.823167,2.09482641 332.277645,9.28226006 C335.830204,13.9525756 336.0035,19.233758 336.0035,21.226657 C336.0035,22.1797827 335.912519,25.2081227 334.877078,28.0674996 C333.923953,30.5759529 330.977928,35.8571353 323.96379,38.0233299 L337.558827,62.9562296 L324.483677,62.9562296 Z"
              id="Fill-6"
            ></path>
            <path
              d="M386.454172,18.8048515 C383.248204,13.6103169 378.40026,10.75094 372.256932,10.75094 C368.44443,10.75094 365.671701,11.964009 364.462964,12.5705435 C360.134907,14.9057012 354.767077,20.6201226 354.767077,32.0489652 C354.767077,34.3884554 354.849392,45.6440025 363.423191,51.2674436 C367.144713,53.6935816 370.866235,53.8668771 372.425895,53.8668771 C373.638964,53.8668771 376.411693,53.7802293 379.440033,52.3938648 C383.941386,50.3186504 389.829103,45.1241158 390.089046,33.6086253 C390.175694,28.5873863 389.222568,23.2195561 386.454172,18.8048515 Z M393.117386,55.7731284 C391.735354,57.2418083 388.182795,60.7943674 382.732649,62.7829341 C380.822066,63.4761163 377.273839,64.5158897 372.083637,64.5158897 C363.423191,64.5158897 356.842292,61.6565129 351.12787,55.0799461 C345.413449,48.5856947 342.991643,40.9693545 342.991643,32.4822041 C342.991643,30.4936375 343.073959,25.8146572 344.811247,20.6201226 C348.792712,8.41144984 359.268429,0.275222952 372.339248,0.275222952 C384.114681,0.275222952 391.12882,6.68282655 393.983864,9.97110994 C397.189832,13.6103169 401.691184,20.7067703 401.604537,32.3955563 C401.604537,36.2037264 401.171298,47.0260346 393.117386,55.7731284 Z"
              id="Fill-7"
            ></path>
            <path
              d="M443.204137,16.1187702 C440.604704,11.6174178 435.496817,11.6174178 431.082113,11.6174178 L422.335019,11.6174178 L422.335019,32.8287953 L430.562226,32.8287953 C431.337723,32.8287953 432.208534,32.7421475 432.984031,32.7421475 C438.876081,32.4822041 441.471182,30.6626007 442.944194,28.2407951 C443.550729,27.2010217 444.58617,24.9481793 444.58617,21.8331915 C444.58617,19.9312727 444.152931,17.8517259 443.204137,16.1187702 Z M448.225377,38.7165122 C442.944194,42.0914433 437.229773,42.1780911 431.255408,42.1780911 L422.335019,42.1780911 L422.335019,62.9562296 L410.910509,62.9562296 L410.910509,1.83488306 L431.511019,1.83488306 C432.984031,1.83488306 434.457044,1.74823527 435.930056,1.83488306 C438.611805,2.00817862 446.319125,2.52806533 451.773603,8.93566893 C456.188308,14.0392234 456.361603,19.7579771 456.361603,22.1797827 C456.361603,31.7023741 451.427012,36.6369654 448.225377,38.7165122 Z"
              id="Fill-8"
            ></path>
            <path
              d="M502.241605,36.4680022 C499.122285,34.9083421 495.049839,34.9949899 487.866738,34.9949899 L481.459134,34.9949899 L481.459134,53.0870471 L494.274341,53.0870471 C498.082511,53.0870471 502.410568,53.3469904 505.269945,49.9720593 C506.049775,49.1055814 507.171864,47.5459213 507.171864,44.6042291 C507.171864,42.8712734 506.651977,38.7165122 502.241605,36.4680022 Z M502.757159,14.4724623 C499.815467,10.8375878 494.447637,11.1841789 490.375191,11.1841789 L481.459134,11.1841789 L481.459134,26.4211917 L485.444932,26.4211917 C488.473272,26.4211917 491.501612,26.4211917 494.620932,26.2478961 C497.475977,26.0789329 500.248706,25.7280094 502.32392,23.475167 C503.017103,22.7863171 504.230171,21.3133048 504.230171,18.7182037 C504.230171,18.0250214 504.056876,16.1187702 502.757159,14.4724623 Z M510.806738,60.1878329 C505.44324,63.216173 499.98443,62.9562296 494.101046,62.9562296 L470.121271,62.9562296 L470.121271,1.83488306 L492.021499,1.83488306 C495.049839,1.83488306 498.169159,1.83488306 501.197499,2.09482641 C503.10375,2.26812198 508.124989,2.78800868 512.106455,7.02941769 C513.406172,8.41144984 516.001273,11.7040656 516.001273,16.8119525 C516.001273,18.5449081 515.572366,24.6059206 510.46448,27.8075562 C508.991467,28.7606818 507.605103,29.107273 505.876479,29.4495317 C506.651977,29.6228273 508.558228,29.9694184 510.551127,31.0958396 C513.666115,32.7421475 518.864982,36.8102609 518.864982,45.6440025 C518.864982,51.7916627 516.265549,57.0685127 510.806738,60.1878329 Z"
              id="Fill-17"
            ></path>
            <path
              d="M549.746252,12.8261544 L541.259102,40.9693545 L558.146755,40.9693545 L549.746252,12.8261544 Z M564.723322,62.9562296 L561.175095,51.0118327 L538.230762,51.0118327 L534.682535,62.9562296 L522.387215,62.9562296 L543.078705,1.83488306 L556.062876,1.83488306 L576.931994,62.9562296 L564.723322,62.9562296 Z"
              id="Fill-18"
            ></path>
            <path
              d="M629.613847,54.1268205 C626.75447,57.7660274 620.091255,64.5158897 607.795935,64.5158897 C605.716388,64.5158897 600.781797,64.3425942 595.500614,61.9164562 C588.573124,58.6281728 579.223828,50.3186504 579.223828,32.2222608 C579.223828,28.9339774 579.223828,20.1868836 584.851602,12.1373045 C587.797626,7.89156313 594.807432,0.275222952 608.229174,0.275222952 C619.91796,0.275222952 626.06562,6.07629207 628.829684,9.97110994 C630.822583,12.9128022 632.039985,15.9454746 633.075426,19.1471102 L621.737563,22.0931349 C621.646583,21.3133048 621.299992,19.3204058 620.173571,17.2451914 C617.838413,12.9128022 613.250413,10.6642922 608.402469,10.75094 C604.94089,10.75094 602.514752,11.7907134 601.301683,12.3972479 C596.800331,14.6457579 591.605797,20.5334748 591.605797,31.9623174 C591.605797,33.6952731 591.692444,40.9693545 595.240671,46.4195001 C596.45374,48.2391036 597.926752,49.7987637 599.655376,50.9251849 C603.294583,53.4336382 606.929457,53.6935816 608.575765,53.6935816 C613.332728,53.6935816 616.885287,51.7916627 619.82698,48.1524558 C621.044381,46.6794435 621.993174,44.8641724 622.253118,44.4309335 C622.599709,43.6511034 622.859652,42.9579212 623.379539,41.4025935 L634.201847,45.9039458 C632.90213,49.0189336 631.693394,51.4407392 629.613847,54.1268205"
              id="Fill-19"
            ></path>
            <polyline
              id="Fill-20"
              points="680.086181 62.9562296 663.813727 34.2151598 653.853564 45.6440025 653.853564 62.9562296 642.515702 62.9562296 642.515702 1.83488306 653.853564 1.83488306 653.853564 29.8827706 678.357557 1.83488306 691.774967 1.83488306 671.772326 24.8615316 693.161331 62.9562296 680.086181 62.9562296"
            ></polyline>
            <path
              d="M733.248928,16.1187702 C732.209155,15.2522924 730.129608,13.6969646 726.836992,12.8261544 C723.8953,12.0506567 722.33564,12.1373045 716.96781,12.1373045 L710.91113,12.1373045 L710.91113,52.6538082 L713.93947,52.6538082 C720.08713,52.6538082 723.722005,52.5671604 726.490401,52.1382538 C728.054394,51.8783105 732.382451,51.1807958 735.497438,47.3726257 C739.305608,42.5246823 739.132313,33.6086253 739.132313,32.8287953 C739.132313,23.7351104 736.363916,18.8914993 733.248928,16.1187702 Z M733.937778,61.3142541 C728.656596,62.9562296 724.415187,62.9562296 718.960709,62.9562296 L699.482287,62.9562296 L699.482287,1.83488306 L721.386847,1.83488306 C724.155244,1.83488306 727.534507,2.00817862 730.216256,2.52806533 C733.248928,3.13459981 739.825495,5.12316645 744.933382,11.7040656 C747.615131,15.2522924 751.254338,22.0931349 751.254338,32.915443 C751.254338,45.037468 746.57969,57.2418083 733.937778,61.3142541 Z"
              id="Fill-21"
            ></path>
            <path
              d="M790.540443,13.5236691 C788.547544,12.2239523 786.212386,11.7040656 781.36011,11.7040656 L772.097462,11.7040656 L772.097462,30.7492485 L776.165576,30.7492485 C782.833123,30.7492485 786.987884,30.7492485 789.760613,29.2805685 C791.489236,28.3274429 794.344281,26.0789329 794.344281,21.1400093 C794.344281,19.5846815 794.170985,15.9454746 790.540443,13.5236691 Z M794.435261,62.9562296 L782.399884,40.2718399 L772.097462,40.2718399 L772.097462,62.9562296 L760.755267,62.9562296 L760.755267,1.83488306 L785.774815,1.83488306 C788.547544,1.83488306 796.770419,2.09482641 802.229229,9.28226006 C805.777456,13.9525756 805.950751,19.233758 805.950751,21.226657 C805.950751,22.1797827 805.864103,25.2081227 804.819998,28.0674996 C803.871204,30.5759529 800.929512,35.8571353 793.915374,38.0233299 L807.510411,62.9562296 L794.435261,62.9562296 Z"
              id="Fill-22"
            ></path>
            <path
              d="M856.401423,18.8048515 C853.195455,13.6103169 848.347512,10.75094 842.199852,10.75094 C838.391681,10.75094 835.623285,11.964009 834.414548,12.5705435 C830.082159,14.9057012 824.714329,20.6201226 824.714329,32.0489652 C824.714329,34.3884554 824.800977,45.6440025 833.370442,51.2674436 C837.096297,53.6935816 840.817819,53.8668771 842.373147,53.8668771 C843.590548,53.8668771 846.358945,53.7802293 849.391618,52.3938648 C853.89297,50.3186504 859.776354,45.1241158 860.04063,33.6086253 C860.122946,28.5873863 859.16982,23.2195561 856.401423,18.8048515 Z M863.06897,55.7731284 C861.682606,57.2418083 858.134379,60.7943674 852.675569,62.7829341 C850.77365,63.4761163 847.225423,64.5158897 842.026556,64.5158897 C833.370442,64.5158897 826.793876,61.6565129 821.075122,55.0799461 C815.365033,48.5856947 812.938895,40.9693545 812.938895,32.4822041 C812.938895,30.4936375 813.025543,25.8146572 814.754166,20.6201226 C818.739964,8.41144984 829.215681,0.275222952 842.290832,0.275222952 C854.066266,0.275222952 861.076071,6.68282655 863.935448,9.97110994 C867.137084,13.6103169 871.638436,20.7067703 871.551788,32.3955563 C871.551788,36.2037264 871.118549,47.0260346 863.06897,55.7731284 Z"
              id="Fill-23"
            ></path>
            <path
              d="M913.151389,16.1187702 C910.556288,11.6174178 905.444069,11.6174178 901.029364,11.6174178 L892.286603,11.6174178 L892.286603,32.8287953 L900.509478,32.8287953 C901.289308,32.8287953 902.155785,32.7421475 902.935616,32.7421475 C908.819,32.4822041 911.418434,30.6626007 912.891446,28.2407951 C913.49798,27.2010217 914.537754,24.9481793 914.537754,21.8331915 C914.537754,19.9312727 914.104515,17.8517259 913.151389,16.1187702 Z M918.172628,38.7165122 C912.891446,42.0914433 907.177025,42.1780911 901.206992,42.1780911 L892.286603,42.1780911 L892.286603,62.9562296 L880.85776,62.9562296 L880.85776,1.83488306 L901.462603,1.83488306 C902.935616,1.83488306 904.408628,1.74823527 905.88164,1.83488306 C908.563389,2.00817862 916.266377,2.52806533 921.725187,8.93566893 C926.13556,14.0392234 926.308855,19.7579771 926.308855,22.1797827 C926.308855,31.7023741 921.378596,36.6369654 918.172628,38.7165122 Z"
              id="Fill-24"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoVertical;
