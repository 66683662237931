import { useMemo, useContext } from 'react';
import { useWindowSize } from './useWindowSize';
import { ThemeContext } from 'grommet';
import isBrower from '../lib/isBrowser';

function useMediaQueryMobile() {
  const theme = useContext(ThemeContext);
  const [windowWidth] = useWindowSize();
  const isMobile = useMemo(() => {
    return isBrower
      ? window.matchMedia(
          `(max-width: ${theme?.global?.breakpoints?.small?.value || 768}px)`
        )?.matches
      : true;
  }, [windowWidth, isBrower]);
  return isMobile;
}

export default useMediaQueryMobile;
