import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from '../lib/nodash';

import { useStoryblokState } from '../lib/storyblok';
import usePageBodyComponents from '../components/DynamicPage/usePageBodyComponents';
import Seo from '../components/Seo';
import LogoHeaderAnimation from '../components/LogoHeaderAnimation';

const HomeTemplate = ({ data: { storyblokEntry, site } }) => {
  const story = useStoryblokState(storyblokEntry);

  return (
    <>
      <LogoHeaderAnimation />
      <Seo
        title={get('content.metafields.title', story) || get('title', story)}
        description={get('content.metafields.description', story)}
        image={get('content.seoImage.filename', story)}
        canonical={`${site.siteMetadata.siteUrl}/`}
      />
      {usePageBodyComponents(story)}
    </>
  );
};

HomeTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export default memo(HomeTemplate);

export const query = graphql`
  query StoryblokHomePageContent {
    storyblokEntry(slug: { eq: "home" }) {
      id
      name
      full_slug
      content
      internalId
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
