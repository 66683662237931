import React, { useContext, memo } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'grommet';
import { LazyMotion, m } from 'framer-motion';

import LogoHorizontal from './Logos/LogoHorizontal';
import LogoVertical from './Logos/LogoVertical';
import SmartLink from './SmartLink';
import Logo from './Logos/Logo';
import useMenuOffset from './useMenuOffset';
import styled from 'styled-components';
import useMediaQueryMobile from './useMediaQueryMobile';

const loadFeatures = () =>
  import('../lib/framer/motionFeatures.js').then((res) => res.default);

const MainLogoContainer = styled(m.div)`
  transform: translateY(0);
  position: fixed;
  right: ${(p) => p.theme.global.edgeSize.large};
  top: ${(p) => p.navHeight + 27 + (p.announcementBarOpen ? 10 : -16)}px;
  z-index: 10;

  @media only screen ${(p) =>
      p.theme.global.breakpoints.small.value &&
      `and (min-width: ${p.theme.global.breakpoints.small.value}px)`} {
    transform: translateY(0);
    top: ${(p) => p.navHeight + (p.announcementBarOpen ? 27 : -10) + 30}px;
  }
`;

const LogoHeaderAnimation = () => {
  const theme = useContext(ThemeContext);
  const isMobile = useMediaQueryMobile();
  const announcementBarOpen = useSelector(
    (state) => state.ui.announcementBarOpen
  );
  const navPinned = useSelector((state) => state.ui.navPinned);

  const edgeSize = isMobile
    ? theme.global.edgeSize.medium
    : theme.global.edgeSize.large;
  const medEdgeSize = theme.global.edgeSize.medium;
  const smEdgeSize = theme.global.edgeSize.small;
  const { scrolledToTop, navHeight } = useMenuOffset();
  const transition = {
    duration: 0.5,
    type: 'tween',
  };

  const navOffset = parseInt(theme.global.edgeSize.medium);

  const horizontalVariants = {
    visible: {
      x: 0,
      y: navOffset + parseInt(smEdgeSize),
      opacity: 1,
    },
    invisible: {
      x: -400,
      y: navOffset,
      opacity: 0,
    },
  };
  const verticalVariants = {
    visible: {
      y: navOffset + 307 + parseInt(smEdgeSize),
      opacity: 1,
    },
    invisible: {
      y: 600,
      opacity: 0,
    },
  };
  const logoVariants = {
    large: {
      y: isMobile ? medEdgeSize : smEdgeSize,
      right: edgeSize,
      width: 228,
    },
    default: {
      y: navPinned ? 0 : isMobile ? -78 : -98,
      right: isMobile ? parseInt(smEdgeSize) : parseInt(medEdgeSize),
      width: isMobile ? 60 : 100,
    },
  };
  return (
    <LazyMotion strict features={loadFeatures}>
      <div>
        <m.div
          style={{
            height: '65px',
            position: 'fixed',
            pointerEvents: 'initial',
            zIndex: 10,
            right: `${278 - (isMobile ? parseInt(edgeSize) : 0)}px`,
          }}
          variants={horizontalVariants}
          animate={scrolledToTop ? 'visible' : 'invisible'}
          transition={transition}
          initial="visible"
        >
          <LogoHorizontal style={{ height: '65px' }} />
        </m.div>

        <m.div
          style={{
            height: '2670px',
            width: '63px',
            position: 'fixed',
            pointerEvents: 'initial',
            right: edgeSize,
            zIndex: 10,
          }}
          variants={verticalVariants}
          animate={scrolledToTop ? 'visible' : 'invisible'}
          transition={transition}
          initial="visible"
        >
          <LogoVertical />
        </m.div>

        <MainLogoContainer
          variants={logoVariants}
          edgeSize={edgeSize}
          announcementBarOpen={announcementBarOpen}
          navHeight={navHeight}
          animate={scrolledToTop ? 'large' : 'default'}
          transition={transition}
          initial={'large'}
        >
          <SmartLink fill to="/" a11yTitle="Backdrop">
            <Logo style={{ width: '100%', fill: theme.global.colors.brand }} />
          </SmartLink>
        </MainLogoContainer>
      </div>
    </LazyMotion>
  );
};

LogoHeaderAnimation.propTypes = {};

export default memo(LogoHeaderAnimation);
