import React from 'react';

const LogoHorizontal = (props) => (
  <svg
    viewBox="0 0 4216 65"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="HORZ-BACKDROP" fill="#0A0B09">
        <g id="Group" transform="translate(2115.000000, 0.000000)">
          <path
            d="M1206.00864,36.5483848 C1202.88932,34.9887247 1198.82121,35.0753725 1191.63811,35.0753725 L1185.22617,35.0753725 L1185.22617,53.1674297 L1198.04138,53.1674297 C1201.85388,53.1674297 1206.18194,53.4273731 1209.03698,50.0524419 C1209.81681,49.1859641 1210.94323,47.626304 1210.94323,44.6846117 C1210.94323,42.951656 1210.42335,38.7968948 1206.00864,36.5483848 Z M1206.52853,14.552845 C1203.5825,10.9179704 1198.21467,11.2645616 1194.14656,11.2645616 L1185.22617,11.2645616 L1185.22617,26.5015743 L1189.21197,26.5015743 C1192.24031,26.5015743 1195.26865,26.5015743 1198.38797,26.3282787 C1201.24301,26.1593156 1204.01574,25.808392 1206.09096,23.5555497 C1206.78847,22.8666998 1207.99721,21.3936875 1207.99721,18.7985863 C1207.99721,18.1054041 1207.82391,16.1991528 1206.52853,14.552845 Z M1214.58244,60.2682156 C1209.21028,63.2965556 1203.7558,63.0366123 1197.86808,63.0366123 L1173.88831,63.0366123 L1173.88831,1.91526571 L1195.79287,1.91526571 C1198.82121,1.91526571 1201.94053,1.91526571 1204.96887,2.17520906 C1206.87079,2.34850462 1211.89203,2.86839133 1215.87783,7.10980034 C1217.17754,8.49183248 1219.77264,11.7844483 1219.77264,16.8923351 C1219.77264,18.6252908 1219.3394,24.6863032 1214.23152,27.8879389 C1212.76284,28.8410645 1211.37647,29.1876556 1209.64352,29.5299144 C1210.42335,29.7032099 1212.3296,30.0498011 1214.31817,31.1762222 C1217.43749,32.8225301 1222.63202,36.8906436 1222.63202,45.7243851 C1222.63202,51.8720454 1220.03259,57.1488954 1214.58244,60.2682156 Z"
            id="Fill-1"
            transform="translate(1198.260165, 32.478729) rotate(-360.000000) translate(-1198.260165, -32.478729) "
          ></path>
          <path
            d="M1253.51762,12.9065371 L1245.03047,41.0497372 L1261.91379,41.0497372 L1253.51762,12.9065371 Z M1268.49469,63.0366123 L1264.94647,51.0922153 L1242.00213,51.0922153 L1238.44957,63.0366123 L1226.15425,63.0366123 L1246.85008,1.91526571 L1259.83858,1.91526571 L1280.69903,63.0366123 L1268.49469,63.0366123 Z"
            id="Fill-2"
            transform="translate(1253.426642, 32.475939) rotate(-360.000000) translate(-1253.426642, -32.475939) "
          ></path>
          <path
            d="M1333.38088,54.2072031 C1330.52584,57.84641 1323.85829,64.5962724 1311.56297,64.5962724 C1309.48776,64.5962724 1304.55317,64.4229768 1299.26765,61.9968389 C1292.34449,58.7085555 1282.9952,50.399033 1282.9952,32.3026434 C1282.9952,29.01436 1282.9952,20.2672663 1288.61864,12.2176872 C1291.56466,7.97194578 1298.5788,0.355605601 1311.99621,0.355605601 C1323.685,0.355605601 1329.83266,6.15667472 1332.60539,10.0514926 C1334.59395,12.9931848 1335.80702,16.0258573 1336.84246,19.2274929 L1325.5046,22.1735175 C1325.41795,21.3936875 1325.06703,19.4007884 1323.94494,17.325574 C1321.60978,12.9931848 1317.01745,10.7446749 1312.16951,10.8313226 C1308.70793,10.8313226 1306.28179,11.871096 1305.06872,12.4776305 C1300.56737,14.7261405 1295.37283,20.6138574 1295.37283,32.0427001 C1295.37283,33.7756558 1295.45948,41.0497372 1299.01204,46.4998828 C1300.22078,48.3194862 1301.69379,49.8791463 1303.42675,51.0055675 C1307.06162,53.5140209 1310.6965,53.7739642 1312.3428,53.7739642 C1317.10843,53.7739642 1320.65666,51.8720454 1323.60268,48.2328385 C1324.81142,46.7598261 1325.76454,44.9445551 1326.02016,44.5113161 C1326.36675,43.7314861 1326.63102,43.0383038 1327.15091,41.4829761 L1337.96888,45.9843285 C1336.6735,49.0993163 1335.46043,51.5211218 1333.38088,54.2072031"
            id="Fill-3"
            transform="translate(1310.482042, 32.475939) rotate(-360.000000) translate(-1310.482042, -32.475939) "
          ></path>
          <polyline
            id="Fill-4"
            transform="translate(1371.605555, 32.475939) rotate(-360.000000) translate(-1371.605555, -32.475939) "
            points="1383.85755 63.0366123 1367.58077 34.2955425 1357.62493 45.7243851 1357.62493 63.0366123 1346.28274 63.0366123 1346.28274 1.91526571 1357.62493 1.91526571 1357.62493 29.9631533 1382.1246 1.91526571 1395.54634 1.91526571 1375.54803 24.9419142 1396.92837 63.0366123 1383.85755 63.0366123"
          ></polyline>
          <path
            d="M1437.01597,16.1991528 C1435.97619,15.332675 1433.90098,13.7773473 1430.60836,12.9065371 C1427.66667,12.1310394 1426.10701,12.2176872 1420.73918,12.2176872 L1414.67817,12.2176872 L1414.67817,52.7341908 L1417.70651,52.7341908 C1423.85417,52.7341908 1427.49338,52.647543 1430.2661,52.2186365 C1431.82143,51.9586931 1436.14949,51.2611785 1439.26881,47.4530084 C1443.07698,42.6050649 1442.90368,33.689008 1442.90368,32.9091779 C1442.90368,23.815493 1440.13095,18.9718819 1437.01597,16.1991528 Z M1437.70482,61.3946368 C1432.42797,63.0366123 1428.18222,63.0366123 1422.72775,63.0366123 L1403.24933,63.0366123 L1403.24933,1.91526571 L1425.15388,1.91526571 C1427.92661,1.91526571 1431.30155,2.08856127 1433.98763,2.60844798 C1437.01597,3.21498246 1443.59687,5.2035491 1448.70475,11.7844483 C1451.39083,15.332675 1455.02571,22.1735175 1455.02571,32.9958257 C1455.02571,45.1178506 1450.34673,57.322191 1437.70482,61.3946368 Z"
            id="Fill-5"
            transform="translate(1429.137517, 32.475939) rotate(-360.000000) translate(-1429.137517, -32.475939) "
          ></path>
          <path
            d="M1494.30748,13.6040517 C1492.31458,12.304335 1489.97942,11.7844483 1485.13148,11.7844483 L1475.8645,11.7844483 L1475.8645,30.8296311 L1479.93695,30.8296311 C1486.60449,30.8296311 1490.75925,30.8296311 1493.52765,29.3609512 C1495.26061,28.4078256 1498.11998,26.1593156 1498.11998,21.2203919 C1498.11998,19.6650642 1497.94669,16.0258573 1494.30748,13.6040517 Z M1498.2023,63.0366123 L1486.17125,40.3522225 L1475.8645,40.3522225 L1475.8645,63.0366123 L1464.52664,63.0366123 L1464.52664,1.91526571 L1489.54619,1.91526571 C1492.31458,1.91526571 1500.54179,2.17520906 1505.99627,9.36264271 C1509.54883,14.0329582 1509.72212,19.3141407 1509.72212,21.3070397 C1509.72212,22.2601653 1509.63114,25.2885053 1508.5957,28.1478822 C1507.64257,30.6563355 1504.69655,35.937518 1497.68241,38.1037125 L1511.27745,63.0366123 L1498.2023,63.0366123 Z"
            id="Fill-6"
            transform="translate(1487.902044, 32.475939) rotate(-360.000000) translate(-1487.902044, -32.475939) "
          ></path>
          <path
            d="M1560.17279,18.8852341 C1556.96683,13.6906995 1552.11888,10.8313226 1545.97555,10.8313226 C1542.16305,10.8313226 1539.39032,12.0443916 1538.18159,12.6509261 C1533.85353,14.9860839 1528.4857,20.7005052 1528.4857,32.1293479 C1528.4857,34.468838 1528.56801,45.7243851 1537.14181,51.3478263 C1540.86334,53.7739642 1544.58486,53.9472598 1546.14452,53.9472598 C1547.35759,53.9472598 1550.13032,53.860612 1553.15866,52.4742475 C1557.66001,50.399033 1563.54772,45.2044984 1563.80767,33.689008 C1563.89432,28.6677689 1562.94119,23.2999387 1560.17279,18.8852341 Z M1566.83601,55.853511 C1565.45398,57.322191 1561.90142,60.8747501 1556.45127,62.8633167 C1554.54069,63.556499 1550.99246,64.5962724 1545.80226,64.5962724 C1537.14181,64.5962724 1530.56091,61.7368955 1524.84649,55.1603288 C1519.13207,48.6660774 1516.71027,41.0497372 1516.71027,32.5625868 C1516.71027,30.5740201 1516.79258,25.8950398 1518.52987,20.7005052 C1522.51133,8.49183248 1532.98705,0.355605601 1546.05787,0.355605601 C1557.8333,0.355605601 1564.84744,6.7632092 1567.70249,10.0514926 C1570.90845,13.6906995 1575.40981,20.787153 1575.32316,32.475939 C1575.32316,36.2841091 1574.88992,47.1064173 1566.83601,55.853511 Z"
            id="Fill-7"
            transform="translate(1546.017328, 32.475939) rotate(-360.000000) translate(-1546.017328, -32.475939) "
          ></path>
          <path
            d="M1616.92276,16.1991528 C1614.32333,11.6978005 1609.21544,11.6978005 1604.80073,11.6978005 L1596.05364,11.6978005 L1596.05364,32.9091779 L1604.28085,32.9091779 C1605.05635,32.9091779 1605.92716,32.8225301 1606.70265,32.8225301 C1612.5947,32.5625868 1615.1898,30.7429833 1616.66282,28.3211778 C1617.26935,27.2814044 1618.30479,25.028562 1618.30479,21.9135742 C1618.30479,20.0116553 1617.87155,17.9321085 1616.92276,16.1991528 Z M1621.944,38.7968948 C1616.66282,42.171826 1610.94839,42.2584738 1604.97403,42.2584738 L1596.05364,42.2584738 L1596.05364,63.0366123 L1584.62913,63.0366123 L1584.62913,1.91526571 L1605.22964,1.91526571 C1606.70265,1.91526571 1608.17567,1.82861792 1609.64868,1.91526571 C1612.33043,2.08856127 1620.03775,2.60844798 1625.49223,9.01605158 C1629.90693,14.119606 1630.08023,19.8383598 1630.08023,22.2601653 C1630.08023,31.7827567 1625.14563,36.717348 1621.944,38.7968948 Z"
            id="Fill-8"
            transform="translate(1607.354678, 32.456684) rotate(-360.000000) translate(-1607.354678, -32.456684) "
          ></path>
          <path
            d="M1675.96023,36.5483848 C1672.84091,34.9887247 1668.76846,35.0753725 1661.58536,35.0753725 L1655.17776,35.0753725 L1655.17776,53.1674297 L1667.99296,53.1674297 C1671.80113,53.1674297 1676.12919,53.4273731 1678.98857,50.0524419 C1679.7684,49.1859641 1680.89049,47.626304 1680.89049,44.6846117 C1680.89049,42.951656 1680.3706,38.7968948 1675.96023,36.5483848 Z M1676.47578,14.552845 C1673.53409,10.9179704 1668.16626,11.2645616 1664.09381,11.2645616 L1655.17776,11.2645616 L1655.17776,26.5015743 L1659.16355,26.5015743 C1662.19189,26.5015743 1665.22023,26.5015743 1668.33955,26.3282787 C1671.1946,26.1593156 1673.96733,25.808392 1676.04254,23.5555497 C1676.73572,22.8666998 1677.94879,21.3936875 1677.94879,18.7985863 C1677.94879,18.1054041 1677.7755,16.1991528 1676.47578,14.552845 Z M1684.52536,60.2682156 C1679.16186,63.2965556 1673.70305,63.0366123 1667.81967,63.0366123 L1643.83989,63.0366123 L1643.83989,1.91526571 L1665.74012,1.91526571 C1668.76846,1.91526571 1671.88778,1.91526571 1674.91612,2.17520906 C1676.82237,2.34850462 1681.84361,2.86839133 1685.82508,7.10980034 C1687.12479,8.49183248 1689.7199,11.7844483 1689.7199,16.8923351 C1689.7199,18.6252908 1689.29099,24.6863032 1684.1831,27.8879389 C1682.71009,28.8410645 1681.32372,29.1876556 1679.5951,29.5299144 C1680.3706,29.7032099 1682.27685,30.0498011 1684.26975,31.1762222 C1687.38474,32.8225301 1692.5836,36.8906436 1692.5836,45.7243851 C1692.5836,51.8720454 1689.98417,57.1488954 1684.52536,60.2682156 Z"
            id="Fill-17"
            transform="translate(1668.211749, 32.478729) rotate(-360.000000) translate(-1668.211749, -32.478729) "
          ></path>
          <path
            d="M1723.46487,12.9065371 L1714.97772,41.0497372 L1731.86538,41.0497372 L1723.46487,12.9065371 Z M1738.44194,63.0366123 L1734.89372,51.0922153 L1711.94938,51.0922153 L1708.40116,63.0366123 L1696.10584,63.0366123 L1716.79733,1.91526571 L1729.7815,1.91526571 L1750.65062,63.0366123 L1738.44194,63.0366123 Z"
            id="Fill-18"
            transform="translate(1723.378227, 32.475939) rotate(-360.000000) translate(-1723.378227, -32.475939) "
          ></path>
          <path
            d="M1803.33247,54.2072031 C1800.47309,57.84641 1793.80988,64.5962724 1781.51456,64.5962724 C1779.43501,64.5962724 1774.50042,64.4229768 1769.21924,61.9968389 C1762.29175,58.7085555 1752.94245,50.399033 1752.94245,32.3026434 C1752.94245,29.01436 1752.94245,20.2672663 1758.57022,12.2176872 C1761.51625,7.97194578 1768.52605,0.355605601 1781.9478,0.355605601 C1793.63658,0.355605601 1799.78424,6.15667472 1802.54831,10.0514926 C1804.54121,12.9931848 1805.75861,16.0258573 1806.79405,19.2274929 L1795.45619,22.1735175 C1795.36521,21.3936875 1795.01861,19.4007884 1793.89219,17.325574 C1791.55704,12.9931848 1786.96904,10.7446749 1782.12109,10.8313226 C1778.65951,10.8313226 1776.23337,11.871096 1775.02031,12.4776305 C1770.51895,14.7261405 1765.32442,20.6138574 1765.32442,32.0427001 C1765.32442,33.7756558 1765.41107,41.0497372 1768.95929,46.4998828 C1770.17236,48.3194862 1771.64537,49.8791463 1773.374,51.0055675 C1777.0132,53.5140209 1780.64808,53.7739642 1782.29439,53.7739642 C1787.05135,53.7739642 1790.60391,51.8720454 1793.5456,48.2328385 C1794.763,46.7598261 1795.7118,44.9445551 1795.97174,44.5113161 C1796.31833,43.7314861 1796.57827,43.0383038 1797.09816,41.4829761 L1807.92047,45.9843285 C1806.62075,49.0993163 1805.41202,51.5211218 1803.33247,54.2072031"
            id="Fill-19"
            transform="translate(1780.431460, 32.475939) rotate(-360.000000) translate(-1780.431460, -32.475939) "
          ></path>
          <polyline
            id="Fill-20"
            transform="translate(1841.557139, 32.475939) rotate(-360.000000) translate(-1841.557139, -32.475939) "
            points="1853.8048 63.0366123 1837.53235 34.2955425 1827.57219 45.7243851 1827.57219 63.0366123 1816.23432 63.0366123 1816.23432 1.91526571 1827.57219 1.91526571 1827.57219 29.9631533 1852.07618 1.91526571 1865.49359 1.91526571 1845.49095 24.9419142 1866.87995 63.0366123 1853.8048 63.0366123"
          ></polyline>
          <path
            d="M1906.96755,16.1991528 C1905.92778,15.332675 1903.84823,13.7773473 1900.55561,12.9065371 C1897.61392,12.1310394 1896.05426,12.2176872 1890.68643,12.2176872 L1884.62975,12.2176872 L1884.62975,52.7341908 L1887.65809,52.7341908 C1893.80575,52.7341908 1897.44063,52.647543 1900.20902,52.2186365 C1901.77302,51.9586931 1906.10107,51.2611785 1909.21606,47.4530084 C1913.02423,42.6050649 1912.85094,33.689008 1912.85094,32.9091779 C1912.85094,23.815493 1910.08254,18.9718819 1906.96755,16.1991528 Z M1907.6564,61.3946368 C1902.37522,63.0366123 1898.13381,63.0366123 1892.67933,63.0366123 L1873.20091,63.0366123 L1873.20091,1.91526571 L1895.10547,1.91526571 C1897.87387,1.91526571 1901.25313,2.08856127 1903.93488,2.60844798 C1906.96755,3.21498246 1913.54412,5.2035491 1918.652,11.7844483 C1921.33375,15.332675 1924.97296,22.1735175 1924.97296,32.9958257 C1924.97296,45.1178506 1920.29831,57.322191 1907.6564,61.3946368 Z"
            id="Fill-21"
            transform="translate(1899.086935, 32.475939) rotate(-360.000000) translate(-1899.086935, -32.475939) "
          ></path>
          <path
            d="M1964.25907,13.6040517 C1962.26617,12.304335 1959.93101,11.7844483 1955.07873,11.7844483 L1945.81608,11.7844483 L1945.81608,30.8296311 L1949.8842,30.8296311 C1956.55174,30.8296311 1960.70651,30.8296311 1963.47924,29.3609512 C1965.20786,28.4078256 1968.0629,26.1593156 1968.0629,21.2203919 C1968.0629,19.6650642 1967.88961,16.0258573 1964.25907,13.6040517 Z M1968.15388,63.0366123 L1956.11851,40.3522225 L1945.81608,40.3522225 L1945.81608,63.0366123 L1934.47389,63.0366123 L1934.47389,1.91526571 L1959.49344,1.91526571 C1962.26617,1.91526571 1970.48904,2.17520906 1975.94785,9.36264271 C1979.49608,14.0329582 1979.66937,19.3141407 1979.66937,21.3070397 C1979.66937,22.2601653 1979.58273,25.2885053 1978.53862,28.1478822 C1977.58983,30.6563355 1974.64813,35.937518 1967.634,38.1037125 L1981.22903,63.0366123 L1968.15388,63.0366123 Z"
            id="Fill-22"
            transform="translate(1957.851462, 32.475939) rotate(-360.000000) translate(-1957.851462, -32.475939) "
          ></path>
          <path
            d="M2030.12005,18.8852341 C2026.91408,13.6906995 2022.06613,10.8313226 2015.91847,10.8313226 C2012.1103,10.8313226 2009.34191,12.0443916 2008.13317,12.6509261 C2003.80078,14.9860839 1998.43295,20.7005052 1998.43295,32.1293479 C1998.43295,34.468838 1998.5196,45.7243851 2007.08906,51.3478263 C2010.81492,53.7739642 2014.53644,53.9472598 2016.09177,53.9472598 C2017.30917,53.9472598 2020.07757,53.860612 2023.11024,52.4742475 C2027.61159,50.399033 2033.49498,45.2044984 2033.75925,33.689008 C2033.84157,28.6677689 2032.88844,23.2999387 2030.12005,18.8852341 Z M2036.78759,55.853511 C2035.40123,57.322191 2031.853,60.8747501 2026.39419,62.8633167 C2024.49227,63.556499 2020.94405,64.5962724 2015.74518,64.5962724 C2007.08906,64.5962724 2000.5125,61.7368955 1994.79374,55.1603288 C1989.08366,48.6660774 1986.65752,41.0497372 1986.65752,32.5625868 C1986.65752,30.5740201 1986.74416,25.8950398 1988.47279,20.7005052 C1992.45859,8.49183248 2002.9343,0.355605601 2016.00945,0.355605601 C2027.78489,0.355605601 2034.79469,6.7632092 2037.65407,10.0514926 C2040.85571,13.6906995 2045.35706,20.787153 2045.27041,32.475939 C2045.27041,36.2841091 2044.83717,47.1064173 2036.78759,55.853511 Z"
            id="Fill-23"
            transform="translate(2015.964580, 32.475939) rotate(-360.000000) translate(-2015.964580, -32.475939) "
          ></path>
          <path
            d="M2086.87001,16.1991528 C2084.27491,11.6978005 2079.16269,11.6978005 2074.74799,11.6978005 L2066.00523,11.6978005 L2066.00523,32.9091779 L2074.2281,32.9091779 C2075.00793,32.9091779 2075.87441,32.8225301 2076.65424,32.8225301 C2082.53762,32.5625868 2085.13706,30.7429833 2086.61007,28.3211778 C2087.2166,27.2814044 2088.25638,25.028562 2088.25638,21.9135742 C2088.25638,20.0116553 2087.82314,17.9321085 2086.87001,16.1991528 Z M2091.89125,38.7968948 C2086.61007,42.171826 2080.89565,42.2584738 2074.92561,42.2584738 L2066.00523,42.2584738 L2066.00523,63.0366123 L2054.57638,63.0366123 L2054.57638,1.91526571 L2075.18123,1.91526571 C2076.65424,1.91526571 2078.12725,1.82861792 2079.60026,1.91526571 C2082.28201,2.08856127 2089.985,2.60844798 2095.44381,9.01605158 C2099.85418,14.119606 2100.02748,19.8383598 2100.02748,22.2601653 C2100.02748,31.7827567 2095.09722,36.717348 2091.89125,38.7968948 Z"
            id="Fill-24"
            transform="translate(2077.301930, 32.456684) rotate(-360.000000) translate(-2077.301930, -32.456684) "
          ></path>
          <g
            id="Group-Copy-4"
            transform="translate(582.718622, 32.580383) rotate(-360.000000) translate(-582.718622, -32.580383) translate(0.718622, 0.080383)"
          >
            <path
              d="M32.2900207,36.4680022 C29.1707005,34.9083421 25.1025871,34.9949899 17.9194858,34.9949899 L11.5075498,34.9949899 L11.5075498,53.0870471 L24.322757,53.0870471 C28.1352595,53.0870471 32.4633163,53.3469904 35.3183608,49.9720593 C36.0981908,49.1055814 37.224612,47.5459213 37.224612,44.6042291 C37.224612,42.8712734 36.7047253,38.7165122 32.2900207,36.4680022 Z M32.8099074,14.4724623 C29.8638828,10.8375878 24.4960526,11.1841789 20.4279391,11.1841789 L11.5075498,11.1841789 L11.5075498,26.4211917 L15.4933479,26.4211917 C18.5216879,26.4211917 21.5500279,26.4211917 24.6693482,26.2478961 C27.5243926,26.0789329 30.2971217,25.7280094 32.3723361,23.475167 C33.0698508,22.7863171 34.2785874,21.3133048 34.2785874,18.7182037 C34.2785874,18.0250214 34.1052918,16.1187702 32.8099074,14.4724623 Z M40.8638189,60.1878329 C35.4916563,63.216173 30.0371784,62.9562296 24.1494615,62.9562296 L0.169687333,62.9562296 L0.169687333,1.83488306 L22.074247,1.83488306 C25.1025871,1.83488306 28.2219073,1.83488306 31.2502473,2.09482641 C33.1521662,2.26812198 38.1734052,2.78800868 42.1592033,7.02941769 C43.45892,8.41144984 46.0540212,11.7040656 46.0540212,16.8119525 C46.0540212,18.5449081 45.6207822,24.6059206 40.5128954,27.8075562 C39.0442155,28.7606818 37.6578509,29.107273 35.9248953,29.4495317 C36.7047253,29.6228273 38.6109765,29.9694184 40.5995432,31.0958396 C43.7188634,32.7421475 48.913398,36.8102609 48.913398,45.6440025 C48.913398,51.7916627 46.3139645,57.0685127 40.8638189,60.1878329 Z"
              id="Fill-1"
            ></path>
            <path
              d="M79.7990005,12.8261544 L71.3118501,40.9693545 L88.1951707,40.9693545 L79.7990005,12.8261544 Z M94.7760699,62.9562296 L91.2278432,51.0118327 L68.2835101,51.0118327 L64.7309509,62.9562296 L52.4356304,62.9562296 L73.1314535,1.83488306 L86.1199563,1.83488306 L106.98041,62.9562296 L94.7760699,62.9562296 Z"
              id="Fill-2"
            ></path>
            <path
              d="M159.662263,54.1268205 C156.807218,57.7660274 150.139671,64.5158897 137.844351,64.5158897 C135.769136,64.5158897 130.834545,64.3425942 125.54903,61.9164562 C118.625872,58.6281728 109.276576,50.3186504 109.276576,32.2222608 C109.276576,28.9339774 109.276576,20.1868836 114.900018,12.1373045 C117.846042,7.89156313 124.86018,0.275222952 138.27759,0.275222952 C149.966376,0.275222952 156.114036,6.07629207 158.886765,9.97110994 C160.875332,12.9128022 162.088401,15.9454746 163.123842,19.1471102 L151.785979,22.0931349 C151.699331,21.3133048 151.348408,19.3204058 150.226319,17.2451914 C147.891161,12.9128022 143.298829,10.6642922 138.450885,10.75094 C134.989306,10.75094 132.563168,11.7907134 131.350099,12.3972479 C126.848747,14.6457579 121.654212,20.5334748 121.654212,31.9623174 C121.654212,33.6952731 121.74086,40.9693545 125.293419,46.4195001 C126.502156,48.2391036 127.975168,49.7987637 129.708124,50.9251849 C133.342998,53.4336382 136.977873,53.6935816 138.624181,53.6935816 C143.389809,53.6935816 146.938036,51.7916627 149.88406,48.1524558 C151.092797,46.6794435 152.045922,44.8641724 152.301533,44.4309335 C152.648125,43.6511034 152.9124,42.9579212 153.432287,41.4025935 L164.250263,45.9039458 C162.954878,49.0189336 161.741809,51.4407392 159.662263,54.1268205"
              id="Fill-3"
            ></path>
            <polyline
              id="Fill-4"
              points="210.138929 62.9562296 193.862143 34.2151598 183.906313 45.6440025 183.906313 62.9562296 172.564118 62.9562296 172.564118 1.83488306 183.906313 1.83488306 183.906313 29.8827706 208.405973 1.83488306 221.827715 1.83488306 201.829407 24.8615316 223.209747 62.9562296 210.138929 62.9562296"
            ></polyline>
            <path
              d="M263.297344,16.1187702 C262.257571,15.2522924 260.182356,13.6969646 256.889741,12.8261544 C253.948048,12.0506567 252.388388,12.1373045 247.020558,12.1373045 L240.959546,12.1373045 L240.959546,52.6538082 L243.987886,52.6538082 C250.135546,52.6538082 253.774753,52.5671604 256.547482,52.1382538 C258.10281,51.8783105 262.430866,51.1807958 265.550187,47.3726257 C269.358357,42.5246823 269.185061,33.6086253 269.185061,32.8287953 C269.185061,23.7351104 266.412332,18.8914993 263.297344,16.1187702 Z M263.986194,61.3142541 C258.709344,62.9562296 254.463603,62.9562296 249.009125,62.9562296 L229.530703,62.9562296 L229.530703,1.83488306 L251.435263,1.83488306 C254.207992,1.83488306 257.582923,2.00817862 260.269004,2.52806533 C263.297344,3.13459981 269.878243,5.12316645 274.98613,11.7040656 C277.672212,15.2522924 281.307086,22.0931349 281.307086,32.915443 C281.307086,45.037468 276.628106,57.2418083 263.986194,61.3142541 Z"
              id="Fill-5"
            ></path>
            <path
              d="M320.588859,13.5236691 C318.59596,12.2239523 316.260802,11.7040656 311.412859,11.7040656 L302.145878,11.7040656 L302.145878,30.7492485 L306.218324,30.7492485 C312.885871,30.7492485 317.040632,30.7492485 319.809029,29.2805685 C321.541984,28.3274429 324.401361,26.0789329 324.401361,21.1400093 C324.401361,19.5846815 324.228066,15.9454746 320.588859,13.5236691 Z M324.483677,62.9562296 L312.452632,40.2718399 L302.145878,40.2718399 L302.145878,62.9562296 L290.808016,62.9562296 L290.808016,1.83488306 L315.827563,1.83488306 C318.59596,1.83488306 326.823167,2.09482641 332.277645,9.28226006 C335.830204,13.9525756 336.0035,19.233758 336.0035,21.226657 C336.0035,22.1797827 335.912519,25.2081227 334.877078,28.0674996 C333.923953,30.5759529 330.977928,35.8571353 323.96379,38.0233299 L337.558827,62.9562296 L324.483677,62.9562296 Z"
              id="Fill-6"
            ></path>
            <path
              d="M386.454172,18.8048515 C383.248204,13.6103169 378.40026,10.75094 372.256932,10.75094 C368.44443,10.75094 365.671701,11.964009 364.462964,12.5705435 C360.134907,14.9057012 354.767077,20.6201226 354.767077,32.0489652 C354.767077,34.3884554 354.849392,45.6440025 363.423191,51.2674436 C367.144713,53.6935816 370.866235,53.8668771 372.425895,53.8668771 C373.638964,53.8668771 376.411693,53.7802293 379.440033,52.3938648 C383.941386,50.3186504 389.829103,45.1241158 390.089046,33.6086253 C390.175694,28.5873863 389.222568,23.2195561 386.454172,18.8048515 Z M393.117386,55.7731284 C391.735354,57.2418083 388.182795,60.7943674 382.732649,62.7829341 C380.822066,63.4761163 377.273839,64.5158897 372.083637,64.5158897 C363.423191,64.5158897 356.842292,61.6565129 351.12787,55.0799461 C345.413449,48.5856947 342.991643,40.9693545 342.991643,32.4822041 C342.991643,30.4936375 343.073959,25.8146572 344.811247,20.6201226 C348.792712,8.41144984 359.268429,0.275222952 372.339248,0.275222952 C384.114681,0.275222952 391.12882,6.68282655 393.983864,9.97110994 C397.189832,13.6103169 401.691184,20.7067703 401.604537,32.3955563 C401.604537,36.2037264 401.171298,47.0260346 393.117386,55.7731284 Z"
              id="Fill-7"
            ></path>
            <path
              d="M443.204137,16.1187702 C440.604704,11.6174178 435.496817,11.6174178 431.082113,11.6174178 L422.335019,11.6174178 L422.335019,32.8287953 L430.562226,32.8287953 C431.337723,32.8287953 432.208534,32.7421475 432.984031,32.7421475 C438.876081,32.4822041 441.471182,30.6626007 442.944194,28.2407951 C443.550729,27.2010217 444.58617,24.9481793 444.58617,21.8331915 C444.58617,19.9312727 444.152931,17.8517259 443.204137,16.1187702 Z M448.225377,38.7165122 C442.944194,42.0914433 437.229773,42.1780911 431.255408,42.1780911 L422.335019,42.1780911 L422.335019,62.9562296 L410.910509,62.9562296 L410.910509,1.83488306 L431.511019,1.83488306 C432.984031,1.83488306 434.457044,1.74823527 435.930056,1.83488306 C438.611805,2.00817862 446.319125,2.52806533 451.773603,8.93566893 C456.188308,14.0392234 456.361603,19.7579771 456.361603,22.1797827 C456.361603,31.7023741 451.427012,36.6369654 448.225377,38.7165122 Z"
              id="Fill-8"
            ></path>
            <path
              d="M972.184524,36.4680022 C969.069536,34.9083421 965.001423,34.9949899 957.813989,34.9949899 L951.406386,34.9949899 L951.406386,53.0870471 L964.221593,53.0870471 C968.029763,53.0870471 972.35782,53.3469904 975.217197,49.9720593 C975.997027,49.1055814 977.123448,47.5459213 977.123448,44.6042291 C977.123448,42.8712734 976.603561,38.7165122 972.184524,36.4680022 Z M972.704411,14.4724623 C969.762719,10.8375878 964.394888,11.1841789 960.326775,11.1841789 L951.406386,11.1841789 L951.406386,26.4211917 L955.387851,26.4211917 C958.420524,26.4211917 961.453196,26.4211917 964.568184,26.2478961 C967.427561,26.0789329 970.195958,25.7280094 972.275504,23.475167 C972.964354,22.7863171 974.177423,21.3133048 974.177423,18.7182037 C974.177423,18.0250214 974.004128,16.1187702 972.704411,14.4724623 Z M980.758322,60.1878329 C975.390492,63.216173 969.936014,62.9562296 964.048297,62.9562296 L940.064191,62.9562296 L940.064191,1.83488306 L961.973083,1.83488306 C965.001423,1.83488306 968.120743,1.83488306 971.149083,2.09482641 C973.051002,2.26812198 978.076573,2.78800868 982.053707,7.02941769 C983.353424,8.41144984 985.952857,11.7040656 985.952857,16.8119525 C985.952857,18.5449081 985.519618,24.6059206 980.411731,27.8075562 C978.943051,28.7606818 977.552354,29.107273 975.823731,29.4495317 C976.603561,29.6228273 978.50548,29.9694184 980.498379,31.0958396 C983.617699,32.7421475 988.807901,36.8102609 988.807901,45.6440025 C988.807901,51.7916627 986.208468,57.0685127 980.758322,60.1878329 Z"
              id="Fill-9"
            ></path>
            <path
              d="M1019.6935,12.8261544 L1011.21069,40.9693545 L1028.08967,40.9693545 L1019.6935,12.8261544 Z M1034.67491,62.9562296 L1031.12668,51.0118327 L1008.18235,51.0118327 L1004.62979,62.9562296 L992.338799,62.9562296 L1013.03029,1.83488306 L1026.01446,1.83488306 L1046.87925,62.9562296 L1034.67491,62.9562296 Z"
              id="Fill-10"
            ></path>
            <path
              d="M1099.56543,54.1268205 C1096.70172,57.7660274 1090.03417,64.5158897 1077.74319,64.5158897 C1075.66797,64.5158897 1070.72905,64.3425942 1065.4522,61.9164562 C1058.52471,58.6281728 1049.17541,50.3186504 1049.17541,32.2222608 C1049.17541,28.9339774 1049.17541,20.1868836 1054.80319,12.1373045 C1057.74488,7.89156313 1064.75468,0.275222952 1078.17643,0.275222952 C1089.86521,0.275222952 1096.00854,6.07629207 1098.78127,9.97110994 C1100.77417,12.9128022 1101.9829,15.9454746 1103.02268,19.1471102 L1091.68048,22.0931349 C1091.59817,21.3133048 1091.25158,19.3204058 1090.12515,17.2451914 C1087.78566,12.9128022 1083.19766,10.6642922 1078.34972,10.75094 C1074.88381,10.75094 1072.462,11.7907134 1071.24894,12.3972479 C1066.74758,14.6457579 1061.55305,20.5334748 1061.55305,31.9623174 C1061.55305,33.6952731 1061.6397,40.9693545 1065.18792,46.4195001 C1066.40099,48.2391036 1067.874,49.7987637 1069.60263,50.9251849 C1073.24183,53.4336382 1076.87671,53.6935816 1078.52302,53.6935816 C1083.28431,53.6935816 1086.83254,51.7916627 1089.77856,48.1524558 C1090.99163,46.6794435 1091.94476,44.8641724 1092.20037,44.4309335 C1092.54696,43.6511034 1092.8069,42.9579212 1093.32679,41.4025935 L1104.1491,45.9039458 C1102.84938,49.0189336 1101.64065,51.4407392 1099.56543,54.1268205"
              id="Fill-11"
            ></path>
            <polyline
              id="Fill-12"
              points="1150.03776 62.9562296 1133.75665 34.2151598 1123.80082 45.6440025 1123.80082 62.9562296 1112.45862 62.9562296 1112.45862 1.83488306 1123.80082 1.83488306 1123.80082 29.8827706 1148.30481 1.83488306 1161.72222 1.83488306 1141.72391 24.8615316 1163.11292 62.9562296 1150.03776 62.9562296"
            ></polyline>
            <path
              d="M502.241605,36.4680022 C499.122285,34.9083421 495.049839,34.9949899 487.866738,34.9949899 L481.459134,34.9949899 L481.459134,53.0870471 L494.274341,53.0870471 C498.082511,53.0870471 502.410568,53.3469904 505.269945,49.9720593 C506.049775,49.1055814 507.171864,47.5459213 507.171864,44.6042291 C507.171864,42.8712734 506.651977,38.7165122 502.241605,36.4680022 Z M502.757159,14.4724623 C499.815467,10.8375878 494.447637,11.1841789 490.375191,11.1841789 L481.459134,11.1841789 L481.459134,26.4211917 L485.444932,26.4211917 C488.473272,26.4211917 491.501612,26.4211917 494.620932,26.2478961 C497.475977,26.0789329 500.248706,25.7280094 502.32392,23.475167 C503.017103,22.7863171 504.230171,21.3133048 504.230171,18.7182037 C504.230171,18.0250214 504.056876,16.1187702 502.757159,14.4724623 Z M510.806738,60.1878329 C505.44324,63.216173 499.98443,62.9562296 494.101046,62.9562296 L470.121271,62.9562296 L470.121271,1.83488306 L492.021499,1.83488306 C495.049839,1.83488306 498.169159,1.83488306 501.197499,2.09482641 C503.10375,2.26812198 508.124989,2.78800868 512.106455,7.02941769 C513.406172,8.41144984 516.001273,11.7040656 516.001273,16.8119525 C516.001273,18.5449081 515.572366,24.6059206 510.46448,27.8075562 C508.991467,28.7606818 507.605103,29.107273 505.876479,29.4495317 C506.651977,29.6228273 508.558228,29.9694184 510.551127,31.0958396 C513.666115,32.7421475 518.864982,36.8102609 518.864982,45.6440025 C518.864982,51.7916627 516.265549,57.0685127 510.806738,60.1878329 Z"
              id="Fill-17"
            ></path>
            <path
              d="M549.746252,12.8261544 L541.259102,40.9693545 L558.146755,40.9693545 L549.746252,12.8261544 Z M564.723322,62.9562296 L561.175095,51.0118327 L538.230762,51.0118327 L534.682535,62.9562296 L522.387215,62.9562296 L543.078705,1.83488306 L556.062876,1.83488306 L576.931994,62.9562296 L564.723322,62.9562296 Z"
              id="Fill-18"
            ></path>
            <path
              d="M629.613847,54.1268205 C626.75447,57.7660274 620.091255,64.5158897 607.795935,64.5158897 C605.716388,64.5158897 600.781797,64.3425942 595.500614,61.9164562 C588.573124,58.6281728 579.223828,50.3186504 579.223828,32.2222608 C579.223828,28.9339774 579.223828,20.1868836 584.851602,12.1373045 C587.797626,7.89156313 594.807432,0.275222952 608.229174,0.275222952 C619.91796,0.275222952 626.06562,6.07629207 628.829684,9.97110994 C630.822583,12.9128022 632.039985,15.9454746 633.075426,19.1471102 L621.737563,22.0931349 C621.646583,21.3133048 621.299992,19.3204058 620.173571,17.2451914 C617.838413,12.9128022 613.250413,10.6642922 608.402469,10.75094 C604.94089,10.75094 602.514752,11.7907134 601.301683,12.3972479 C596.800331,14.6457579 591.605797,20.5334748 591.605797,31.9623174 C591.605797,33.6952731 591.692444,40.9693545 595.240671,46.4195001 C596.45374,48.2391036 597.926752,49.7987637 599.655376,50.9251849 C603.294583,53.4336382 606.929457,53.6935816 608.575765,53.6935816 C613.332728,53.6935816 616.885287,51.7916627 619.82698,48.1524558 C621.044381,46.6794435 621.993174,44.8641724 622.253118,44.4309335 C622.599709,43.6511034 622.859652,42.9579212 623.379539,41.4025935 L634.201847,45.9039458 C632.90213,49.0189336 631.693394,51.4407392 629.613847,54.1268205"
              id="Fill-19"
            ></path>
            <polyline
              id="Fill-20"
              points="680.086181 62.9562296 663.813727 34.2151598 653.853564 45.6440025 653.853564 62.9562296 642.515702 62.9562296 642.515702 1.83488306 653.853564 1.83488306 653.853564 29.8827706 678.357557 1.83488306 691.774967 1.83488306 671.772326 24.8615316 693.161331 62.9562296 680.086181 62.9562296"
            ></polyline>
            <path
              d="M733.248928,16.1187702 C732.209155,15.2522924 730.129608,13.6969646 726.836992,12.8261544 C723.8953,12.0506567 722.33564,12.1373045 716.96781,12.1373045 L710.91113,12.1373045 L710.91113,52.6538082 L713.93947,52.6538082 C720.08713,52.6538082 723.722005,52.5671604 726.490401,52.1382538 C728.054394,51.8783105 732.382451,51.1807958 735.497438,47.3726257 C739.305608,42.5246823 739.132313,33.6086253 739.132313,32.8287953 C739.132313,23.7351104 736.363916,18.8914993 733.248928,16.1187702 Z M733.937778,61.3142541 C728.656596,62.9562296 724.415187,62.9562296 718.960709,62.9562296 L699.482287,62.9562296 L699.482287,1.83488306 L721.386847,1.83488306 C724.155244,1.83488306 727.534507,2.00817862 730.216256,2.52806533 C733.248928,3.13459981 739.825495,5.12316645 744.933382,11.7040656 C747.615131,15.2522924 751.254338,22.0931349 751.254338,32.915443 C751.254338,45.037468 746.57969,57.2418083 733.937778,61.3142541 Z"
              id="Fill-21"
            ></path>
            <path
              d="M790.540443,13.5236691 C788.547544,12.2239523 786.212386,11.7040656 781.36011,11.7040656 L772.097462,11.7040656 L772.097462,30.7492485 L776.165576,30.7492485 C782.833123,30.7492485 786.987884,30.7492485 789.760613,29.2805685 C791.489236,28.3274429 794.344281,26.0789329 794.344281,21.1400093 C794.344281,19.5846815 794.170985,15.9454746 790.540443,13.5236691 Z M794.435261,62.9562296 L782.399884,40.2718399 L772.097462,40.2718399 L772.097462,62.9562296 L760.755267,62.9562296 L760.755267,1.83488306 L785.774815,1.83488306 C788.547544,1.83488306 796.770419,2.09482641 802.229229,9.28226006 C805.777456,13.9525756 805.950751,19.233758 805.950751,21.226657 C805.950751,22.1797827 805.864103,25.2081227 804.819998,28.0674996 C803.871204,30.5759529 800.929512,35.8571353 793.915374,38.0233299 L807.510411,62.9562296 L794.435261,62.9562296 Z"
              id="Fill-22"
            ></path>
            <path
              d="M856.401423,18.8048515 C853.195455,13.6103169 848.347512,10.75094 842.199852,10.75094 C838.391681,10.75094 835.623285,11.964009 834.414548,12.5705435 C830.082159,14.9057012 824.714329,20.6201226 824.714329,32.0489652 C824.714329,34.3884554 824.800977,45.6440025 833.370442,51.2674436 C837.096297,53.6935816 840.817819,53.8668771 842.373147,53.8668771 C843.590548,53.8668771 846.358945,53.7802293 849.391618,52.3938648 C853.89297,50.3186504 859.776354,45.1241158 860.04063,33.6086253 C860.122946,28.5873863 859.16982,23.2195561 856.401423,18.8048515 Z M863.06897,55.7731284 C861.682606,57.2418083 858.134379,60.7943674 852.675569,62.7829341 C850.77365,63.4761163 847.225423,64.5158897 842.026556,64.5158897 C833.370442,64.5158897 826.793876,61.6565129 821.075122,55.0799461 C815.365033,48.5856947 812.938895,40.9693545 812.938895,32.4822041 C812.938895,30.4936375 813.025543,25.8146572 814.754166,20.6201226 C818.739964,8.41144984 829.215681,0.275222952 842.290832,0.275222952 C854.066266,0.275222952 861.076071,6.68282655 863.935448,9.97110994 C867.137084,13.6103169 871.638436,20.7067703 871.551788,32.3955563 C871.551788,36.2037264 871.118549,47.0260346 863.06897,55.7731284 Z"
              id="Fill-23"
            ></path>
            <path
              d="M913.151389,16.1187702 C910.556288,11.6174178 905.444069,11.6174178 901.029364,11.6174178 L892.286603,11.6174178 L892.286603,32.8287953 L900.509478,32.8287953 C901.289308,32.8287953 902.155785,32.7421475 902.935616,32.7421475 C908.819,32.4822041 911.418434,30.6626007 912.891446,28.2407951 C913.49798,27.2010217 914.537754,24.9481793 914.537754,21.8331915 C914.537754,19.9312727 914.104515,17.8517259 913.151389,16.1187702 Z M918.172628,38.7165122 C912.891446,42.0914433 907.177025,42.1780911 901.206992,42.1780911 L892.286603,42.1780911 L892.286603,62.9562296 L880.85776,62.9562296 L880.85776,1.83488306 L901.462603,1.83488306 C902.935616,1.83488306 904.408628,1.74823527 905.88164,1.83488306 C908.563389,2.00817862 916.266377,2.52806533 921.725187,8.93566893 C926.13556,14.0392234 926.308855,19.7579771 926.308855,22.1797827 C926.308855,31.7023741 921.378596,36.6369654 918.172628,38.7165122 Z"
              id="Fill-24"
            ></path>
          </g>
        </g>
        <g id="Group">
          <path
            d="M1206.00864,36.5483848 C1202.88932,34.9887247 1198.82121,35.0753725 1191.63811,35.0753725 L1185.22617,35.0753725 L1185.22617,53.1674297 L1198.04138,53.1674297 C1201.85388,53.1674297 1206.18194,53.4273731 1209.03698,50.0524419 C1209.81681,49.1859641 1210.94323,47.626304 1210.94323,44.6846117 C1210.94323,42.951656 1210.42335,38.7968948 1206.00864,36.5483848 Z M1206.52853,14.552845 C1203.5825,10.9179704 1198.21467,11.2645616 1194.14656,11.2645616 L1185.22617,11.2645616 L1185.22617,26.5015743 L1189.21197,26.5015743 C1192.24031,26.5015743 1195.26865,26.5015743 1198.38797,26.3282787 C1201.24301,26.1593156 1204.01574,25.808392 1206.09096,23.5555497 C1206.78847,22.8666998 1207.99721,21.3936875 1207.99721,18.7985863 C1207.99721,18.1054041 1207.82391,16.1991528 1206.52853,14.552845 Z M1214.58244,60.2682156 C1209.21028,63.2965556 1203.7558,63.0366123 1197.86808,63.0366123 L1173.88831,63.0366123 L1173.88831,1.91526571 L1195.79287,1.91526571 C1198.82121,1.91526571 1201.94053,1.91526571 1204.96887,2.17520906 C1206.87079,2.34850462 1211.89203,2.86839133 1215.87783,7.10980034 C1217.17754,8.49183248 1219.77264,11.7844483 1219.77264,16.8923351 C1219.77264,18.6252908 1219.3394,24.6863032 1214.23152,27.8879389 C1212.76284,28.8410645 1211.37647,29.1876556 1209.64352,29.5299144 C1210.42335,29.7032099 1212.3296,30.0498011 1214.31817,31.1762222 C1217.43749,32.8225301 1222.63202,36.8906436 1222.63202,45.7243851 C1222.63202,51.8720454 1220.03259,57.1488954 1214.58244,60.2682156 Z"
            id="Fill-1"
            transform="translate(1198.260165, 32.478729) rotate(-360.000000) translate(-1198.260165, -32.478729) "
          ></path>
          <path
            d="M1253.51762,12.9065371 L1245.03047,41.0497372 L1261.91379,41.0497372 L1253.51762,12.9065371 Z M1268.49469,63.0366123 L1264.94647,51.0922153 L1242.00213,51.0922153 L1238.44957,63.0366123 L1226.15425,63.0366123 L1246.85008,1.91526571 L1259.83858,1.91526571 L1280.69903,63.0366123 L1268.49469,63.0366123 Z"
            id="Fill-2"
            transform="translate(1253.426642, 32.475939) rotate(-360.000000) translate(-1253.426642, -32.475939) "
          ></path>
          <path
            d="M1333.38088,54.2072031 C1330.52584,57.84641 1323.85829,64.5962724 1311.56297,64.5962724 C1309.48776,64.5962724 1304.55317,64.4229768 1299.26765,61.9968389 C1292.34449,58.7085555 1282.9952,50.399033 1282.9952,32.3026434 C1282.9952,29.01436 1282.9952,20.2672663 1288.61864,12.2176872 C1291.56466,7.97194578 1298.5788,0.355605601 1311.99621,0.355605601 C1323.685,0.355605601 1329.83266,6.15667472 1332.60539,10.0514926 C1334.59395,12.9931848 1335.80702,16.0258573 1336.84246,19.2274929 L1325.5046,22.1735175 C1325.41795,21.3936875 1325.06703,19.4007884 1323.94494,17.325574 C1321.60978,12.9931848 1317.01745,10.7446749 1312.16951,10.8313226 C1308.70793,10.8313226 1306.28179,11.871096 1305.06872,12.4776305 C1300.56737,14.7261405 1295.37283,20.6138574 1295.37283,32.0427001 C1295.37283,33.7756558 1295.45948,41.0497372 1299.01204,46.4998828 C1300.22078,48.3194862 1301.69379,49.8791463 1303.42675,51.0055675 C1307.06162,53.5140209 1310.6965,53.7739642 1312.3428,53.7739642 C1317.10843,53.7739642 1320.65666,51.8720454 1323.60268,48.2328385 C1324.81142,46.7598261 1325.76454,44.9445551 1326.02016,44.5113161 C1326.36675,43.7314861 1326.63102,43.0383038 1327.15091,41.4829761 L1337.96888,45.9843285 C1336.6735,49.0993163 1335.46043,51.5211218 1333.38088,54.2072031"
            id="Fill-3"
            transform="translate(1310.482042, 32.475939) rotate(-360.000000) translate(-1310.482042, -32.475939) "
          ></path>
          <polyline
            id="Fill-4"
            transform="translate(1371.605555, 32.475939) rotate(-360.000000) translate(-1371.605555, -32.475939) "
            points="1383.85755 63.0366123 1367.58077 34.2955425 1357.62493 45.7243851 1357.62493 63.0366123 1346.28274 63.0366123 1346.28274 1.91526571 1357.62493 1.91526571 1357.62493 29.9631533 1382.1246 1.91526571 1395.54634 1.91526571 1375.54803 24.9419142 1396.92837 63.0366123 1383.85755 63.0366123"
          ></polyline>
          <path
            d="M1437.01597,16.1991528 C1435.97619,15.332675 1433.90098,13.7773473 1430.60836,12.9065371 C1427.66667,12.1310394 1426.10701,12.2176872 1420.73918,12.2176872 L1414.67817,12.2176872 L1414.67817,52.7341908 L1417.70651,52.7341908 C1423.85417,52.7341908 1427.49338,52.647543 1430.2661,52.2186365 C1431.82143,51.9586931 1436.14949,51.2611785 1439.26881,47.4530084 C1443.07698,42.6050649 1442.90368,33.689008 1442.90368,32.9091779 C1442.90368,23.815493 1440.13095,18.9718819 1437.01597,16.1991528 Z M1437.70482,61.3946368 C1432.42797,63.0366123 1428.18222,63.0366123 1422.72775,63.0366123 L1403.24933,63.0366123 L1403.24933,1.91526571 L1425.15388,1.91526571 C1427.92661,1.91526571 1431.30155,2.08856127 1433.98763,2.60844798 C1437.01597,3.21498246 1443.59687,5.2035491 1448.70475,11.7844483 C1451.39083,15.332675 1455.02571,22.1735175 1455.02571,32.9958257 C1455.02571,45.1178506 1450.34673,57.322191 1437.70482,61.3946368 Z"
            id="Fill-5"
            transform="translate(1429.137517, 32.475939) rotate(-360.000000) translate(-1429.137517, -32.475939) "
          ></path>
          <path
            d="M1494.30748,13.6040517 C1492.31458,12.304335 1489.97942,11.7844483 1485.13148,11.7844483 L1475.8645,11.7844483 L1475.8645,30.8296311 L1479.93695,30.8296311 C1486.60449,30.8296311 1490.75925,30.8296311 1493.52765,29.3609512 C1495.26061,28.4078256 1498.11998,26.1593156 1498.11998,21.2203919 C1498.11998,19.6650642 1497.94669,16.0258573 1494.30748,13.6040517 Z M1498.2023,63.0366123 L1486.17125,40.3522225 L1475.8645,40.3522225 L1475.8645,63.0366123 L1464.52664,63.0366123 L1464.52664,1.91526571 L1489.54619,1.91526571 C1492.31458,1.91526571 1500.54179,2.17520906 1505.99627,9.36264271 C1509.54883,14.0329582 1509.72212,19.3141407 1509.72212,21.3070397 C1509.72212,22.2601653 1509.63114,25.2885053 1508.5957,28.1478822 C1507.64257,30.6563355 1504.69655,35.937518 1497.68241,38.1037125 L1511.27745,63.0366123 L1498.2023,63.0366123 Z"
            id="Fill-6"
            transform="translate(1487.902044, 32.475939) rotate(-360.000000) translate(-1487.902044, -32.475939) "
          ></path>
          <path
            d="M1560.17279,18.8852341 C1556.96683,13.6906995 1552.11888,10.8313226 1545.97555,10.8313226 C1542.16305,10.8313226 1539.39032,12.0443916 1538.18159,12.6509261 C1533.85353,14.9860839 1528.4857,20.7005052 1528.4857,32.1293479 C1528.4857,34.468838 1528.56801,45.7243851 1537.14181,51.3478263 C1540.86334,53.7739642 1544.58486,53.9472598 1546.14452,53.9472598 C1547.35759,53.9472598 1550.13032,53.860612 1553.15866,52.4742475 C1557.66001,50.399033 1563.54772,45.2044984 1563.80767,33.689008 C1563.89432,28.6677689 1562.94119,23.2999387 1560.17279,18.8852341 Z M1566.83601,55.853511 C1565.45398,57.322191 1561.90142,60.8747501 1556.45127,62.8633167 C1554.54069,63.556499 1550.99246,64.5962724 1545.80226,64.5962724 C1537.14181,64.5962724 1530.56091,61.7368955 1524.84649,55.1603288 C1519.13207,48.6660774 1516.71027,41.0497372 1516.71027,32.5625868 C1516.71027,30.5740201 1516.79258,25.8950398 1518.52987,20.7005052 C1522.51133,8.49183248 1532.98705,0.355605601 1546.05787,0.355605601 C1557.8333,0.355605601 1564.84744,6.7632092 1567.70249,10.0514926 C1570.90845,13.6906995 1575.40981,20.787153 1575.32316,32.475939 C1575.32316,36.2841091 1574.88992,47.1064173 1566.83601,55.853511 Z"
            id="Fill-7"
            transform="translate(1546.017328, 32.475939) rotate(-360.000000) translate(-1546.017328, -32.475939) "
          ></path>
          <path
            d="M1616.92276,16.1991528 C1614.32333,11.6978005 1609.21544,11.6978005 1604.80073,11.6978005 L1596.05364,11.6978005 L1596.05364,32.9091779 L1604.28085,32.9091779 C1605.05635,32.9091779 1605.92716,32.8225301 1606.70265,32.8225301 C1612.5947,32.5625868 1615.1898,30.7429833 1616.66282,28.3211778 C1617.26935,27.2814044 1618.30479,25.028562 1618.30479,21.9135742 C1618.30479,20.0116553 1617.87155,17.9321085 1616.92276,16.1991528 Z M1621.944,38.7968948 C1616.66282,42.171826 1610.94839,42.2584738 1604.97403,42.2584738 L1596.05364,42.2584738 L1596.05364,63.0366123 L1584.62913,63.0366123 L1584.62913,1.91526571 L1605.22964,1.91526571 C1606.70265,1.91526571 1608.17567,1.82861792 1609.64868,1.91526571 C1612.33043,2.08856127 1620.03775,2.60844798 1625.49223,9.01605158 C1629.90693,14.119606 1630.08023,19.8383598 1630.08023,22.2601653 C1630.08023,31.7827567 1625.14563,36.717348 1621.944,38.7968948 Z"
            id="Fill-8"
            transform="translate(1607.354678, 32.456684) rotate(-360.000000) translate(-1607.354678, -32.456684) "
          ></path>
          <path
            d="M1675.96023,36.5483848 C1672.84091,34.9887247 1668.76846,35.0753725 1661.58536,35.0753725 L1655.17776,35.0753725 L1655.17776,53.1674297 L1667.99296,53.1674297 C1671.80113,53.1674297 1676.12919,53.4273731 1678.98857,50.0524419 C1679.7684,49.1859641 1680.89049,47.626304 1680.89049,44.6846117 C1680.89049,42.951656 1680.3706,38.7968948 1675.96023,36.5483848 Z M1676.47578,14.552845 C1673.53409,10.9179704 1668.16626,11.2645616 1664.09381,11.2645616 L1655.17776,11.2645616 L1655.17776,26.5015743 L1659.16355,26.5015743 C1662.19189,26.5015743 1665.22023,26.5015743 1668.33955,26.3282787 C1671.1946,26.1593156 1673.96733,25.808392 1676.04254,23.5555497 C1676.73572,22.8666998 1677.94879,21.3936875 1677.94879,18.7985863 C1677.94879,18.1054041 1677.7755,16.1991528 1676.47578,14.552845 Z M1684.52536,60.2682156 C1679.16186,63.2965556 1673.70305,63.0366123 1667.81967,63.0366123 L1643.83989,63.0366123 L1643.83989,1.91526571 L1665.74012,1.91526571 C1668.76846,1.91526571 1671.88778,1.91526571 1674.91612,2.17520906 C1676.82237,2.34850462 1681.84361,2.86839133 1685.82508,7.10980034 C1687.12479,8.49183248 1689.7199,11.7844483 1689.7199,16.8923351 C1689.7199,18.6252908 1689.29099,24.6863032 1684.1831,27.8879389 C1682.71009,28.8410645 1681.32372,29.1876556 1679.5951,29.5299144 C1680.3706,29.7032099 1682.27685,30.0498011 1684.26975,31.1762222 C1687.38474,32.8225301 1692.5836,36.8906436 1692.5836,45.7243851 C1692.5836,51.8720454 1689.98417,57.1488954 1684.52536,60.2682156 Z"
            id="Fill-17"
            transform="translate(1668.211749, 32.478729) rotate(-360.000000) translate(-1668.211749, -32.478729) "
          ></path>
          <path
            d="M1723.46487,12.9065371 L1714.97772,41.0497372 L1731.86538,41.0497372 L1723.46487,12.9065371 Z M1738.44194,63.0366123 L1734.89372,51.0922153 L1711.94938,51.0922153 L1708.40116,63.0366123 L1696.10584,63.0366123 L1716.79733,1.91526571 L1729.7815,1.91526571 L1750.65062,63.0366123 L1738.44194,63.0366123 Z"
            id="Fill-18"
            transform="translate(1723.378227, 32.475939) rotate(-360.000000) translate(-1723.378227, -32.475939) "
          ></path>
          <path
            d="M1803.33247,54.2072031 C1800.47309,57.84641 1793.80988,64.5962724 1781.51456,64.5962724 C1779.43501,64.5962724 1774.50042,64.4229768 1769.21924,61.9968389 C1762.29175,58.7085555 1752.94245,50.399033 1752.94245,32.3026434 C1752.94245,29.01436 1752.94245,20.2672663 1758.57022,12.2176872 C1761.51625,7.97194578 1768.52605,0.355605601 1781.9478,0.355605601 C1793.63658,0.355605601 1799.78424,6.15667472 1802.54831,10.0514926 C1804.54121,12.9931848 1805.75861,16.0258573 1806.79405,19.2274929 L1795.45619,22.1735175 C1795.36521,21.3936875 1795.01861,19.4007884 1793.89219,17.325574 C1791.55704,12.9931848 1786.96904,10.7446749 1782.12109,10.8313226 C1778.65951,10.8313226 1776.23337,11.871096 1775.02031,12.4776305 C1770.51895,14.7261405 1765.32442,20.6138574 1765.32442,32.0427001 C1765.32442,33.7756558 1765.41107,41.0497372 1768.95929,46.4998828 C1770.17236,48.3194862 1771.64537,49.8791463 1773.374,51.0055675 C1777.0132,53.5140209 1780.64808,53.7739642 1782.29439,53.7739642 C1787.05135,53.7739642 1790.60391,51.8720454 1793.5456,48.2328385 C1794.763,46.7598261 1795.7118,44.9445551 1795.97174,44.5113161 C1796.31833,43.7314861 1796.57827,43.0383038 1797.09816,41.4829761 L1807.92047,45.9843285 C1806.62075,49.0993163 1805.41202,51.5211218 1803.33247,54.2072031"
            id="Fill-19"
            transform="translate(1780.431460, 32.475939) rotate(-360.000000) translate(-1780.431460, -32.475939) "
          ></path>
          <polyline
            id="Fill-20"
            transform="translate(1841.557139, 32.475939) rotate(-360.000000) translate(-1841.557139, -32.475939) "
            points="1853.8048 63.0366123 1837.53235 34.2955425 1827.57219 45.7243851 1827.57219 63.0366123 1816.23432 63.0366123 1816.23432 1.91526571 1827.57219 1.91526571 1827.57219 29.9631533 1852.07618 1.91526571 1865.49359 1.91526571 1845.49095 24.9419142 1866.87995 63.0366123 1853.8048 63.0366123"
          ></polyline>
          <path
            d="M1906.96755,16.1991528 C1905.92778,15.332675 1903.84823,13.7773473 1900.55561,12.9065371 C1897.61392,12.1310394 1896.05426,12.2176872 1890.68643,12.2176872 L1884.62975,12.2176872 L1884.62975,52.7341908 L1887.65809,52.7341908 C1893.80575,52.7341908 1897.44063,52.647543 1900.20902,52.2186365 C1901.77302,51.9586931 1906.10107,51.2611785 1909.21606,47.4530084 C1913.02423,42.6050649 1912.85094,33.689008 1912.85094,32.9091779 C1912.85094,23.815493 1910.08254,18.9718819 1906.96755,16.1991528 Z M1907.6564,61.3946368 C1902.37522,63.0366123 1898.13381,63.0366123 1892.67933,63.0366123 L1873.20091,63.0366123 L1873.20091,1.91526571 L1895.10547,1.91526571 C1897.87387,1.91526571 1901.25313,2.08856127 1903.93488,2.60844798 C1906.96755,3.21498246 1913.54412,5.2035491 1918.652,11.7844483 C1921.33375,15.332675 1924.97296,22.1735175 1924.97296,32.9958257 C1924.97296,45.1178506 1920.29831,57.322191 1907.6564,61.3946368 Z"
            id="Fill-21"
            transform="translate(1899.086935, 32.475939) rotate(-360.000000) translate(-1899.086935, -32.475939) "
          ></path>
          <path
            d="M1964.25907,13.6040517 C1962.26617,12.304335 1959.93101,11.7844483 1955.07873,11.7844483 L1945.81608,11.7844483 L1945.81608,30.8296311 L1949.8842,30.8296311 C1956.55174,30.8296311 1960.70651,30.8296311 1963.47924,29.3609512 C1965.20786,28.4078256 1968.0629,26.1593156 1968.0629,21.2203919 C1968.0629,19.6650642 1967.88961,16.0258573 1964.25907,13.6040517 Z M1968.15388,63.0366123 L1956.11851,40.3522225 L1945.81608,40.3522225 L1945.81608,63.0366123 L1934.47389,63.0366123 L1934.47389,1.91526571 L1959.49344,1.91526571 C1962.26617,1.91526571 1970.48904,2.17520906 1975.94785,9.36264271 C1979.49608,14.0329582 1979.66937,19.3141407 1979.66937,21.3070397 C1979.66937,22.2601653 1979.58273,25.2885053 1978.53862,28.1478822 C1977.58983,30.6563355 1974.64813,35.937518 1967.634,38.1037125 L1981.22903,63.0366123 L1968.15388,63.0366123 Z"
            id="Fill-22"
            transform="translate(1957.851462, 32.475939) rotate(-360.000000) translate(-1957.851462, -32.475939) "
          ></path>
          <path
            d="M2030.12005,18.8852341 C2026.91408,13.6906995 2022.06613,10.8313226 2015.91847,10.8313226 C2012.1103,10.8313226 2009.34191,12.0443916 2008.13317,12.6509261 C2003.80078,14.9860839 1998.43295,20.7005052 1998.43295,32.1293479 C1998.43295,34.468838 1998.5196,45.7243851 2007.08906,51.3478263 C2010.81492,53.7739642 2014.53644,53.9472598 2016.09177,53.9472598 C2017.30917,53.9472598 2020.07757,53.860612 2023.11024,52.4742475 C2027.61159,50.399033 2033.49498,45.2044984 2033.75925,33.689008 C2033.84157,28.6677689 2032.88844,23.2999387 2030.12005,18.8852341 Z M2036.78759,55.853511 C2035.40123,57.322191 2031.853,60.8747501 2026.39419,62.8633167 C2024.49227,63.556499 2020.94405,64.5962724 2015.74518,64.5962724 C2007.08906,64.5962724 2000.5125,61.7368955 1994.79374,55.1603288 C1989.08366,48.6660774 1986.65752,41.0497372 1986.65752,32.5625868 C1986.65752,30.5740201 1986.74416,25.8950398 1988.47279,20.7005052 C1992.45859,8.49183248 2002.9343,0.355605601 2016.00945,0.355605601 C2027.78489,0.355605601 2034.79469,6.7632092 2037.65407,10.0514926 C2040.85571,13.6906995 2045.35706,20.787153 2045.27041,32.475939 C2045.27041,36.2841091 2044.83717,47.1064173 2036.78759,55.853511 Z"
            id="Fill-23"
            transform="translate(2015.964580, 32.475939) rotate(-360.000000) translate(-2015.964580, -32.475939) "
          ></path>
          <path
            d="M2086.87001,16.1991528 C2084.27491,11.6978005 2079.16269,11.6978005 2074.74799,11.6978005 L2066.00523,11.6978005 L2066.00523,32.9091779 L2074.2281,32.9091779 C2075.00793,32.9091779 2075.87441,32.8225301 2076.65424,32.8225301 C2082.53762,32.5625868 2085.13706,30.7429833 2086.61007,28.3211778 C2087.2166,27.2814044 2088.25638,25.028562 2088.25638,21.9135742 C2088.25638,20.0116553 2087.82314,17.9321085 2086.87001,16.1991528 Z M2091.89125,38.7968948 C2086.61007,42.171826 2080.89565,42.2584738 2074.92561,42.2584738 L2066.00523,42.2584738 L2066.00523,63.0366123 L2054.57638,63.0366123 L2054.57638,1.91526571 L2075.18123,1.91526571 C2076.65424,1.91526571 2078.12725,1.82861792 2079.60026,1.91526571 C2082.28201,2.08856127 2089.985,2.60844798 2095.44381,9.01605158 C2099.85418,14.119606 2100.02748,19.8383598 2100.02748,22.2601653 C2100.02748,31.7827567 2095.09722,36.717348 2091.89125,38.7968948 Z"
            id="Fill-24"
            transform="translate(2077.301930, 32.456684) rotate(-360.000000) translate(-2077.301930, -32.456684) "
          ></path>
          <g
            id="Group-Copy-4"
            transform="translate(582.718622, 32.580383) rotate(-360.000000) translate(-582.718622, -32.580383) translate(0.718622, 0.080383)"
          >
            <path
              d="M32.2900207,36.4680022 C29.1707005,34.9083421 25.1025871,34.9949899 17.9194858,34.9949899 L11.5075498,34.9949899 L11.5075498,53.0870471 L24.322757,53.0870471 C28.1352595,53.0870471 32.4633163,53.3469904 35.3183608,49.9720593 C36.0981908,49.1055814 37.224612,47.5459213 37.224612,44.6042291 C37.224612,42.8712734 36.7047253,38.7165122 32.2900207,36.4680022 Z M32.8099074,14.4724623 C29.8638828,10.8375878 24.4960526,11.1841789 20.4279391,11.1841789 L11.5075498,11.1841789 L11.5075498,26.4211917 L15.4933479,26.4211917 C18.5216879,26.4211917 21.5500279,26.4211917 24.6693482,26.2478961 C27.5243926,26.0789329 30.2971217,25.7280094 32.3723361,23.475167 C33.0698508,22.7863171 34.2785874,21.3133048 34.2785874,18.7182037 C34.2785874,18.0250214 34.1052918,16.1187702 32.8099074,14.4724623 Z M40.8638189,60.1878329 C35.4916563,63.216173 30.0371784,62.9562296 24.1494615,62.9562296 L0.169687333,62.9562296 L0.169687333,1.83488306 L22.074247,1.83488306 C25.1025871,1.83488306 28.2219073,1.83488306 31.2502473,2.09482641 C33.1521662,2.26812198 38.1734052,2.78800868 42.1592033,7.02941769 C43.45892,8.41144984 46.0540212,11.7040656 46.0540212,16.8119525 C46.0540212,18.5449081 45.6207822,24.6059206 40.5128954,27.8075562 C39.0442155,28.7606818 37.6578509,29.107273 35.9248953,29.4495317 C36.7047253,29.6228273 38.6109765,29.9694184 40.5995432,31.0958396 C43.7188634,32.7421475 48.913398,36.8102609 48.913398,45.6440025 C48.913398,51.7916627 46.3139645,57.0685127 40.8638189,60.1878329 Z"
              id="Fill-1"
            ></path>
            <path
              d="M79.7990005,12.8261544 L71.3118501,40.9693545 L88.1951707,40.9693545 L79.7990005,12.8261544 Z M94.7760699,62.9562296 L91.2278432,51.0118327 L68.2835101,51.0118327 L64.7309509,62.9562296 L52.4356304,62.9562296 L73.1314535,1.83488306 L86.1199563,1.83488306 L106.98041,62.9562296 L94.7760699,62.9562296 Z"
              id="Fill-2"
            ></path>
            <path
              d="M159.662263,54.1268205 C156.807218,57.7660274 150.139671,64.5158897 137.844351,64.5158897 C135.769136,64.5158897 130.834545,64.3425942 125.54903,61.9164562 C118.625872,58.6281728 109.276576,50.3186504 109.276576,32.2222608 C109.276576,28.9339774 109.276576,20.1868836 114.900018,12.1373045 C117.846042,7.89156313 124.86018,0.275222952 138.27759,0.275222952 C149.966376,0.275222952 156.114036,6.07629207 158.886765,9.97110994 C160.875332,12.9128022 162.088401,15.9454746 163.123842,19.1471102 L151.785979,22.0931349 C151.699331,21.3133048 151.348408,19.3204058 150.226319,17.2451914 C147.891161,12.9128022 143.298829,10.6642922 138.450885,10.75094 C134.989306,10.75094 132.563168,11.7907134 131.350099,12.3972479 C126.848747,14.6457579 121.654212,20.5334748 121.654212,31.9623174 C121.654212,33.6952731 121.74086,40.9693545 125.293419,46.4195001 C126.502156,48.2391036 127.975168,49.7987637 129.708124,50.9251849 C133.342998,53.4336382 136.977873,53.6935816 138.624181,53.6935816 C143.389809,53.6935816 146.938036,51.7916627 149.88406,48.1524558 C151.092797,46.6794435 152.045922,44.8641724 152.301533,44.4309335 C152.648125,43.6511034 152.9124,42.9579212 153.432287,41.4025935 L164.250263,45.9039458 C162.954878,49.0189336 161.741809,51.4407392 159.662263,54.1268205"
              id="Fill-3"
            ></path>
            <polyline
              id="Fill-4"
              points="210.138929 62.9562296 193.862143 34.2151598 183.906313 45.6440025 183.906313 62.9562296 172.564118 62.9562296 172.564118 1.83488306 183.906313 1.83488306 183.906313 29.8827706 208.405973 1.83488306 221.827715 1.83488306 201.829407 24.8615316 223.209747 62.9562296 210.138929 62.9562296"
            ></polyline>
            <path
              d="M263.297344,16.1187702 C262.257571,15.2522924 260.182356,13.6969646 256.889741,12.8261544 C253.948048,12.0506567 252.388388,12.1373045 247.020558,12.1373045 L240.959546,12.1373045 L240.959546,52.6538082 L243.987886,52.6538082 C250.135546,52.6538082 253.774753,52.5671604 256.547482,52.1382538 C258.10281,51.8783105 262.430866,51.1807958 265.550187,47.3726257 C269.358357,42.5246823 269.185061,33.6086253 269.185061,32.8287953 C269.185061,23.7351104 266.412332,18.8914993 263.297344,16.1187702 Z M263.986194,61.3142541 C258.709344,62.9562296 254.463603,62.9562296 249.009125,62.9562296 L229.530703,62.9562296 L229.530703,1.83488306 L251.435263,1.83488306 C254.207992,1.83488306 257.582923,2.00817862 260.269004,2.52806533 C263.297344,3.13459981 269.878243,5.12316645 274.98613,11.7040656 C277.672212,15.2522924 281.307086,22.0931349 281.307086,32.915443 C281.307086,45.037468 276.628106,57.2418083 263.986194,61.3142541 Z"
              id="Fill-5"
            ></path>
            <path
              d="M320.588859,13.5236691 C318.59596,12.2239523 316.260802,11.7040656 311.412859,11.7040656 L302.145878,11.7040656 L302.145878,30.7492485 L306.218324,30.7492485 C312.885871,30.7492485 317.040632,30.7492485 319.809029,29.2805685 C321.541984,28.3274429 324.401361,26.0789329 324.401361,21.1400093 C324.401361,19.5846815 324.228066,15.9454746 320.588859,13.5236691 Z M324.483677,62.9562296 L312.452632,40.2718399 L302.145878,40.2718399 L302.145878,62.9562296 L290.808016,62.9562296 L290.808016,1.83488306 L315.827563,1.83488306 C318.59596,1.83488306 326.823167,2.09482641 332.277645,9.28226006 C335.830204,13.9525756 336.0035,19.233758 336.0035,21.226657 C336.0035,22.1797827 335.912519,25.2081227 334.877078,28.0674996 C333.923953,30.5759529 330.977928,35.8571353 323.96379,38.0233299 L337.558827,62.9562296 L324.483677,62.9562296 Z"
              id="Fill-6"
            ></path>
            <path
              d="M386.454172,18.8048515 C383.248204,13.6103169 378.40026,10.75094 372.256932,10.75094 C368.44443,10.75094 365.671701,11.964009 364.462964,12.5705435 C360.134907,14.9057012 354.767077,20.6201226 354.767077,32.0489652 C354.767077,34.3884554 354.849392,45.6440025 363.423191,51.2674436 C367.144713,53.6935816 370.866235,53.8668771 372.425895,53.8668771 C373.638964,53.8668771 376.411693,53.7802293 379.440033,52.3938648 C383.941386,50.3186504 389.829103,45.1241158 390.089046,33.6086253 C390.175694,28.5873863 389.222568,23.2195561 386.454172,18.8048515 Z M393.117386,55.7731284 C391.735354,57.2418083 388.182795,60.7943674 382.732649,62.7829341 C380.822066,63.4761163 377.273839,64.5158897 372.083637,64.5158897 C363.423191,64.5158897 356.842292,61.6565129 351.12787,55.0799461 C345.413449,48.5856947 342.991643,40.9693545 342.991643,32.4822041 C342.991643,30.4936375 343.073959,25.8146572 344.811247,20.6201226 C348.792712,8.41144984 359.268429,0.275222952 372.339248,0.275222952 C384.114681,0.275222952 391.12882,6.68282655 393.983864,9.97110994 C397.189832,13.6103169 401.691184,20.7067703 401.604537,32.3955563 C401.604537,36.2037264 401.171298,47.0260346 393.117386,55.7731284 Z"
              id="Fill-7"
            ></path>
            <path
              d="M443.204137,16.1187702 C440.604704,11.6174178 435.496817,11.6174178 431.082113,11.6174178 L422.335019,11.6174178 L422.335019,32.8287953 L430.562226,32.8287953 C431.337723,32.8287953 432.208534,32.7421475 432.984031,32.7421475 C438.876081,32.4822041 441.471182,30.6626007 442.944194,28.2407951 C443.550729,27.2010217 444.58617,24.9481793 444.58617,21.8331915 C444.58617,19.9312727 444.152931,17.8517259 443.204137,16.1187702 Z M448.225377,38.7165122 C442.944194,42.0914433 437.229773,42.1780911 431.255408,42.1780911 L422.335019,42.1780911 L422.335019,62.9562296 L410.910509,62.9562296 L410.910509,1.83488306 L431.511019,1.83488306 C432.984031,1.83488306 434.457044,1.74823527 435.930056,1.83488306 C438.611805,2.00817862 446.319125,2.52806533 451.773603,8.93566893 C456.188308,14.0392234 456.361603,19.7579771 456.361603,22.1797827 C456.361603,31.7023741 451.427012,36.6369654 448.225377,38.7165122 Z"
              id="Fill-8"
            ></path>
            <path
              d="M972.184524,36.4680022 C969.069536,34.9083421 965.001423,34.9949899 957.813989,34.9949899 L951.406386,34.9949899 L951.406386,53.0870471 L964.221593,53.0870471 C968.029763,53.0870471 972.35782,53.3469904 975.217197,49.9720593 C975.997027,49.1055814 977.123448,47.5459213 977.123448,44.6042291 C977.123448,42.8712734 976.603561,38.7165122 972.184524,36.4680022 Z M972.704411,14.4724623 C969.762719,10.8375878 964.394888,11.1841789 960.326775,11.1841789 L951.406386,11.1841789 L951.406386,26.4211917 L955.387851,26.4211917 C958.420524,26.4211917 961.453196,26.4211917 964.568184,26.2478961 C967.427561,26.0789329 970.195958,25.7280094 972.275504,23.475167 C972.964354,22.7863171 974.177423,21.3133048 974.177423,18.7182037 C974.177423,18.0250214 974.004128,16.1187702 972.704411,14.4724623 Z M980.758322,60.1878329 C975.390492,63.216173 969.936014,62.9562296 964.048297,62.9562296 L940.064191,62.9562296 L940.064191,1.83488306 L961.973083,1.83488306 C965.001423,1.83488306 968.120743,1.83488306 971.149083,2.09482641 C973.051002,2.26812198 978.076573,2.78800868 982.053707,7.02941769 C983.353424,8.41144984 985.952857,11.7040656 985.952857,16.8119525 C985.952857,18.5449081 985.519618,24.6059206 980.411731,27.8075562 C978.943051,28.7606818 977.552354,29.107273 975.823731,29.4495317 C976.603561,29.6228273 978.50548,29.9694184 980.498379,31.0958396 C983.617699,32.7421475 988.807901,36.8102609 988.807901,45.6440025 C988.807901,51.7916627 986.208468,57.0685127 980.758322,60.1878329 Z"
              id="Fill-9"
            ></path>
            <path
              d="M1019.6935,12.8261544 L1011.21069,40.9693545 L1028.08967,40.9693545 L1019.6935,12.8261544 Z M1034.67491,62.9562296 L1031.12668,51.0118327 L1008.18235,51.0118327 L1004.62979,62.9562296 L992.338799,62.9562296 L1013.03029,1.83488306 L1026.01446,1.83488306 L1046.87925,62.9562296 L1034.67491,62.9562296 Z"
              id="Fill-10"
            ></path>
            <path
              d="M1099.56543,54.1268205 C1096.70172,57.7660274 1090.03417,64.5158897 1077.74319,64.5158897 C1075.66797,64.5158897 1070.72905,64.3425942 1065.4522,61.9164562 C1058.52471,58.6281728 1049.17541,50.3186504 1049.17541,32.2222608 C1049.17541,28.9339774 1049.17541,20.1868836 1054.80319,12.1373045 C1057.74488,7.89156313 1064.75468,0.275222952 1078.17643,0.275222952 C1089.86521,0.275222952 1096.00854,6.07629207 1098.78127,9.97110994 C1100.77417,12.9128022 1101.9829,15.9454746 1103.02268,19.1471102 L1091.68048,22.0931349 C1091.59817,21.3133048 1091.25158,19.3204058 1090.12515,17.2451914 C1087.78566,12.9128022 1083.19766,10.6642922 1078.34972,10.75094 C1074.88381,10.75094 1072.462,11.7907134 1071.24894,12.3972479 C1066.74758,14.6457579 1061.55305,20.5334748 1061.55305,31.9623174 C1061.55305,33.6952731 1061.6397,40.9693545 1065.18792,46.4195001 C1066.40099,48.2391036 1067.874,49.7987637 1069.60263,50.9251849 C1073.24183,53.4336382 1076.87671,53.6935816 1078.52302,53.6935816 C1083.28431,53.6935816 1086.83254,51.7916627 1089.77856,48.1524558 C1090.99163,46.6794435 1091.94476,44.8641724 1092.20037,44.4309335 C1092.54696,43.6511034 1092.8069,42.9579212 1093.32679,41.4025935 L1104.1491,45.9039458 C1102.84938,49.0189336 1101.64065,51.4407392 1099.56543,54.1268205"
              id="Fill-11"
            ></path>
            <polyline
              id="Fill-12"
              points="1150.03776 62.9562296 1133.75665 34.2151598 1123.80082 45.6440025 1123.80082 62.9562296 1112.45862 62.9562296 1112.45862 1.83488306 1123.80082 1.83488306 1123.80082 29.8827706 1148.30481 1.83488306 1161.72222 1.83488306 1141.72391 24.8615316 1163.11292 62.9562296 1150.03776 62.9562296"
            ></polyline>
            <path
              d="M502.241605,36.4680022 C499.122285,34.9083421 495.049839,34.9949899 487.866738,34.9949899 L481.459134,34.9949899 L481.459134,53.0870471 L494.274341,53.0870471 C498.082511,53.0870471 502.410568,53.3469904 505.269945,49.9720593 C506.049775,49.1055814 507.171864,47.5459213 507.171864,44.6042291 C507.171864,42.8712734 506.651977,38.7165122 502.241605,36.4680022 Z M502.757159,14.4724623 C499.815467,10.8375878 494.447637,11.1841789 490.375191,11.1841789 L481.459134,11.1841789 L481.459134,26.4211917 L485.444932,26.4211917 C488.473272,26.4211917 491.501612,26.4211917 494.620932,26.2478961 C497.475977,26.0789329 500.248706,25.7280094 502.32392,23.475167 C503.017103,22.7863171 504.230171,21.3133048 504.230171,18.7182037 C504.230171,18.0250214 504.056876,16.1187702 502.757159,14.4724623 Z M510.806738,60.1878329 C505.44324,63.216173 499.98443,62.9562296 494.101046,62.9562296 L470.121271,62.9562296 L470.121271,1.83488306 L492.021499,1.83488306 C495.049839,1.83488306 498.169159,1.83488306 501.197499,2.09482641 C503.10375,2.26812198 508.124989,2.78800868 512.106455,7.02941769 C513.406172,8.41144984 516.001273,11.7040656 516.001273,16.8119525 C516.001273,18.5449081 515.572366,24.6059206 510.46448,27.8075562 C508.991467,28.7606818 507.605103,29.107273 505.876479,29.4495317 C506.651977,29.6228273 508.558228,29.9694184 510.551127,31.0958396 C513.666115,32.7421475 518.864982,36.8102609 518.864982,45.6440025 C518.864982,51.7916627 516.265549,57.0685127 510.806738,60.1878329 Z"
              id="Fill-17"
            ></path>
            <path
              d="M549.746252,12.8261544 L541.259102,40.9693545 L558.146755,40.9693545 L549.746252,12.8261544 Z M564.723322,62.9562296 L561.175095,51.0118327 L538.230762,51.0118327 L534.682535,62.9562296 L522.387215,62.9562296 L543.078705,1.83488306 L556.062876,1.83488306 L576.931994,62.9562296 L564.723322,62.9562296 Z"
              id="Fill-18"
            ></path>
            <path
              d="M629.613847,54.1268205 C626.75447,57.7660274 620.091255,64.5158897 607.795935,64.5158897 C605.716388,64.5158897 600.781797,64.3425942 595.500614,61.9164562 C588.573124,58.6281728 579.223828,50.3186504 579.223828,32.2222608 C579.223828,28.9339774 579.223828,20.1868836 584.851602,12.1373045 C587.797626,7.89156313 594.807432,0.275222952 608.229174,0.275222952 C619.91796,0.275222952 626.06562,6.07629207 628.829684,9.97110994 C630.822583,12.9128022 632.039985,15.9454746 633.075426,19.1471102 L621.737563,22.0931349 C621.646583,21.3133048 621.299992,19.3204058 620.173571,17.2451914 C617.838413,12.9128022 613.250413,10.6642922 608.402469,10.75094 C604.94089,10.75094 602.514752,11.7907134 601.301683,12.3972479 C596.800331,14.6457579 591.605797,20.5334748 591.605797,31.9623174 C591.605797,33.6952731 591.692444,40.9693545 595.240671,46.4195001 C596.45374,48.2391036 597.926752,49.7987637 599.655376,50.9251849 C603.294583,53.4336382 606.929457,53.6935816 608.575765,53.6935816 C613.332728,53.6935816 616.885287,51.7916627 619.82698,48.1524558 C621.044381,46.6794435 621.993174,44.8641724 622.253118,44.4309335 C622.599709,43.6511034 622.859652,42.9579212 623.379539,41.4025935 L634.201847,45.9039458 C632.90213,49.0189336 631.693394,51.4407392 629.613847,54.1268205"
              id="Fill-19"
            ></path>
            <polyline
              id="Fill-20"
              points="680.086181 62.9562296 663.813727 34.2151598 653.853564 45.6440025 653.853564 62.9562296 642.515702 62.9562296 642.515702 1.83488306 653.853564 1.83488306 653.853564 29.8827706 678.357557 1.83488306 691.774967 1.83488306 671.772326 24.8615316 693.161331 62.9562296 680.086181 62.9562296"
            ></polyline>
            <path
              d="M733.248928,16.1187702 C732.209155,15.2522924 730.129608,13.6969646 726.836992,12.8261544 C723.8953,12.0506567 722.33564,12.1373045 716.96781,12.1373045 L710.91113,12.1373045 L710.91113,52.6538082 L713.93947,52.6538082 C720.08713,52.6538082 723.722005,52.5671604 726.490401,52.1382538 C728.054394,51.8783105 732.382451,51.1807958 735.497438,47.3726257 C739.305608,42.5246823 739.132313,33.6086253 739.132313,32.8287953 C739.132313,23.7351104 736.363916,18.8914993 733.248928,16.1187702 Z M733.937778,61.3142541 C728.656596,62.9562296 724.415187,62.9562296 718.960709,62.9562296 L699.482287,62.9562296 L699.482287,1.83488306 L721.386847,1.83488306 C724.155244,1.83488306 727.534507,2.00817862 730.216256,2.52806533 C733.248928,3.13459981 739.825495,5.12316645 744.933382,11.7040656 C747.615131,15.2522924 751.254338,22.0931349 751.254338,32.915443 C751.254338,45.037468 746.57969,57.2418083 733.937778,61.3142541 Z"
              id="Fill-21"
            ></path>
            <path
              d="M790.540443,13.5236691 C788.547544,12.2239523 786.212386,11.7040656 781.36011,11.7040656 L772.097462,11.7040656 L772.097462,30.7492485 L776.165576,30.7492485 C782.833123,30.7492485 786.987884,30.7492485 789.760613,29.2805685 C791.489236,28.3274429 794.344281,26.0789329 794.344281,21.1400093 C794.344281,19.5846815 794.170985,15.9454746 790.540443,13.5236691 Z M794.435261,62.9562296 L782.399884,40.2718399 L772.097462,40.2718399 L772.097462,62.9562296 L760.755267,62.9562296 L760.755267,1.83488306 L785.774815,1.83488306 C788.547544,1.83488306 796.770419,2.09482641 802.229229,9.28226006 C805.777456,13.9525756 805.950751,19.233758 805.950751,21.226657 C805.950751,22.1797827 805.864103,25.2081227 804.819998,28.0674996 C803.871204,30.5759529 800.929512,35.8571353 793.915374,38.0233299 L807.510411,62.9562296 L794.435261,62.9562296 Z"
              id="Fill-22"
            ></path>
            <path
              d="M856.401423,18.8048515 C853.195455,13.6103169 848.347512,10.75094 842.199852,10.75094 C838.391681,10.75094 835.623285,11.964009 834.414548,12.5705435 C830.082159,14.9057012 824.714329,20.6201226 824.714329,32.0489652 C824.714329,34.3884554 824.800977,45.6440025 833.370442,51.2674436 C837.096297,53.6935816 840.817819,53.8668771 842.373147,53.8668771 C843.590548,53.8668771 846.358945,53.7802293 849.391618,52.3938648 C853.89297,50.3186504 859.776354,45.1241158 860.04063,33.6086253 C860.122946,28.5873863 859.16982,23.2195561 856.401423,18.8048515 Z M863.06897,55.7731284 C861.682606,57.2418083 858.134379,60.7943674 852.675569,62.7829341 C850.77365,63.4761163 847.225423,64.5158897 842.026556,64.5158897 C833.370442,64.5158897 826.793876,61.6565129 821.075122,55.0799461 C815.365033,48.5856947 812.938895,40.9693545 812.938895,32.4822041 C812.938895,30.4936375 813.025543,25.8146572 814.754166,20.6201226 C818.739964,8.41144984 829.215681,0.275222952 842.290832,0.275222952 C854.066266,0.275222952 861.076071,6.68282655 863.935448,9.97110994 C867.137084,13.6103169 871.638436,20.7067703 871.551788,32.3955563 C871.551788,36.2037264 871.118549,47.0260346 863.06897,55.7731284 Z"
              id="Fill-23"
            ></path>
            <path
              d="M913.151389,16.1187702 C910.556288,11.6174178 905.444069,11.6174178 901.029364,11.6174178 L892.286603,11.6174178 L892.286603,32.8287953 L900.509478,32.8287953 C901.289308,32.8287953 902.155785,32.7421475 902.935616,32.7421475 C908.819,32.4822041 911.418434,30.6626007 912.891446,28.2407951 C913.49798,27.2010217 914.537754,24.9481793 914.537754,21.8331915 C914.537754,19.9312727 914.104515,17.8517259 913.151389,16.1187702 Z M918.172628,38.7165122 C912.891446,42.0914433 907.177025,42.1780911 901.206992,42.1780911 L892.286603,42.1780911 L892.286603,62.9562296 L880.85776,62.9562296 L880.85776,1.83488306 L901.462603,1.83488306 C902.935616,1.83488306 904.408628,1.74823527 905.88164,1.83488306 C908.563389,2.00817862 916.266377,2.52806533 921.725187,8.93566893 C926.13556,14.0392234 926.308855,19.7579771 926.308855,22.1797827 C926.308855,31.7023741 921.378596,36.6369654 918.172628,38.7165122 Z"
              id="Fill-24"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoHorizontal;
